const root = '/brancardage';

const routes = {
  root,
  settings: root + '/settings',
  fares: root + '/fares',
  team: root + '/team',
};

export default routes;
