import { Cloud, HealthCenter } from '@ambuliz/sabri-core';
import { Button, Stack } from '@mui/material';
import { FormContainer, FormSection } from 'common/components';
import useParseResource from 'common/hooks/useParseResource';
import { color } from 'common/theme';
import { toast } from 'common/toast';
import { useAuthentication } from 'core/authentication';
import { PageContent, PageHeader, PageSection } from 'core/layout';
import { useEffect, useMemo, useState } from 'react';
import { i18n } from 'sabri/locales';

type HealthCenterConfig = {
  autodispatchActivated: boolean;
};

export const SabriSettings: React.FC = () => {
  const { healthCenter: userHealthCenter } = useAuthentication();
  const { resource: healthCenter, isLoading: healthCenterLoading } = useParseResource(
    userHealthCenter?.id,
    HealthCenter,
    {
      enabled: !!userHealthCenter?.id,
    }
  );
  const [config, setConfig] = useState<HealthCenterConfig | undefined>(undefined);
  const [saveLoading, setSaveLoading] = useState(false);
  const hasChanged = useMemo(
    () => !!config && JSON.stringify(config) !== JSON.stringify(getHealthCenterConfig(healthCenter)),
    [config, healthCenter]
  );
  const loading = healthCenterLoading || saveLoading;

  useEffect(() => {
    setConfig(getHealthCenterConfig(healthCenter));
  }, [healthCenter]);

  if (!config) {
    return null;
  }

  const reset = () => {
    setConfig(getHealthCenterConfig(healthCenter));
  };

  const handleChange = (key: keyof HealthCenterConfig) => (value: HealthCenterConfig[typeof key]) => {
    setConfig((prev) => ({ ...(prev || {}), [key]: value }));
  };

  const handleSubmit = async () => {
    if (!config || !healthCenter?.id) {
      return;
    }

    setSaveLoading(true);
    try {
      await Cloud.updateHealthCenter({
        id: healthCenter.id,
        autodispatchActivated: config.autodispatchActivated,
      });
      toast.success(i18n.messageSuccessHealthCenterSettingsUpdated);
    } catch (error) {
      toast.error(error?.message || i18n.globalError);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <PageContent>
      <PageHeader title={i18n.settings} />

      <PageSection>
        <FormContainer>
          <FormSection
            label="Autodispatch"
            checked={config.autodispatchActivated}
            onChange={handleChange('autodispatchActivated')}
          />
        </FormContainer>
      </PageSection>

      <Stack
        direction="row"
        justifyContent="flex-end"
        gap={4}
        position="sticky"
        paddingY={4}
        bgcolor="white"
        borderTop={`1px solid ${color.grey[5]}`}
        margin="8px 40px 0 40px"
        bottom={0}
      >
        <Button variant="outlined" disabled={!hasChanged || loading} onClick={reset}>
          {i18n.cancel}
        </Button>
        <Button disabled={!hasChanged || loading} onClick={handleSubmit}>
          {i18n.save}
        </Button>
      </Stack>
    </PageContent>
  );
};

const getHealthCenterConfig = (healthCenter?: HealthCenter): HealthCenterConfig | undefined =>
  healthCenter
    ? {
        autodispatchActivated: healthCenter.autodispatchActivated,
      }
    : undefined;
