import {
  FarePosition,
  FareSpecificity,
  FareStatus,
  IncidentReason,
  PatientGender,
  WebUserRole,
} from '@ambuliz/sabri-core';
import { DateFilters } from '../const/date';
import { PorterStatusWeb } from '../const/porterStatusWeb';

const fareStatus: Record<FareStatus | 'PULLED', string> = {
  PENDING: 'En attente',
  DRAFT: 'Brouillon',
  IN_PROGRESS: 'En cours',
  DONE: 'Terminée',
  ASSIGNED: 'Assignée',
  PATIENT_CARE: 'Pris en charge',
  STARTED: 'Débutée',
  CANCELED: 'Annulée',
  FAILED: 'Échouée',
  WAITING: 'Appel requis',
  PULLED: 'Patient appelé',
  QUEUED: 'À venir',
};

const farePosition: Record<FarePosition, string> = {
  LYING_DOWN_BED: 'Lit',
  LYING_DOWN: 'Brancard',
  SITTING_UP: 'Assis',
  WALKING: 'Marche',
};

const fareSpecificity: Record<FareSpecificity, string> = {
  ON_DRIP: 'Perfusion',
  GLOVES: 'Gants',
  MASK: 'Masques',
  OXYGEN: 'Oxygène',
  BMR: 'Isolement respiratoire',
  SAP: 'Pousse-seringue',
  NO_CONTACT: 'Isolement contact',
  SCOP: 'Patient scopé',
  BACKREST: 'Dossier',
  APRON: 'Tablier',
  COVID: 'Covid-19',
};

const patientGender: Record<PatientGender, string> = {
  FEMALE: 'Mme',
  MALE: 'M.',
  UNKNOWN: 'Inconnu',
};

const patientGenderShrink: Record<PatientGender, string> = {
  FEMALE: 'F',
  MALE: 'H',
  UNKNOWN: '?',
};

const porterStatus: Record<PorterStatusWeb, string> = {
  AVAILABLE: 'Disponible',
  BUSY: 'En mission',
  OFF: 'Pas en service',
  OFFLINE: 'Déconnecté',
  ON_BREAK: 'En pause',
  ASSIGNED: 'Assigné',
};

const incidentReasons: Record<IncidentReason, string> = {
  PATIENT_NOT_READY: 'Patient non prêt (sous la douche / WC)',
  INADEQUATE_FARE_TRANSPORT: 'Mode de transport inadapté',
  PATIENT_UNFIT: 'Incident - Malaise',
  NEED_HELP: 'Besoin de renforts',
  OTHER: 'Autre incident',
  PATIENT_MISSING: "Patient plus dans l'établissement",
};

const statsHeaders: Record<string, string> = {
  faresDone: 'Missions réalisées',
  portersActivity: 'Occupation des agents',
  delays: 'Retards',
  incidents: 'Incidents',
  faresFailed: 'Missions échouées',
};

const filters: Record<string, string> = {
  reason: 'Motif',
  position: 'Moyen',
  from: 'Départ',
  to: 'Arrivée',
  porter: 'Agent',
  duration: 'Durée',
  delay: 'Retard',
  incident: 'Incident',
  comment: 'Commentaire',
  reference: 'Référence',
  status: 'Statut',
};

const dateFilters: Record<DateFilters, string> = {
  FROM_THE_BEGINNING: 'Depuis le début',
  TODAY: "Aujourd'hui",
  YESTERDAY: 'Hier',
  THIS_WEEK: 'Cette semaine',
  LAST_WEEK: 'La semaine dernière',
  LAST_THIRTY_DAYS: 'Les 30 derniers jours',
  LAST_NINETY_DAYS: 'Les 90 derniers jours',
  LAST_SIX_MONTHS: 'Les 6 derniers mois',
  LAST_TWELVE_MONTHS: 'Les 12 derniers mois',
  THIS_YEAR: 'Cette année civile',
  CUSTOM: 'Personnalisée',
};

export const roles: Record<WebUserRole, string> = {
  MANAGER: 'Créateur',
  REGULATOR: 'Régulateur',
  ADMIN: 'Administrateur',
  ADMIN_REGULATOR: 'Administrateur & Régulateur',
  BED_MANAGER: 'Bed manager',
};

const linkerParamsTrad: Record<string, string> = {
  username: "Nom d'utilisateur",
  password: 'Mot de passe',
  routeName: "Point d'entrée",
  patientId: 'ID du patient',
  fromUnitId: "ID de l'unité de départ",
  toUnitId: "ID de l'unité d'arrivée",
  fromAreaId: 'ID du service de départ',
  toAreaId: "ID du service d'arrivée",
  roundTrip: 'Aller-retour',
  wantedDate: 'Date de transport souhaitée',
  position: 'Position de transport',
  reason: 'Raison du transport',
  comment: 'Commentaire',
  isEmergency: 'Urgence',
  specificities: 'Spécificités',
  ipp: 'IPP du patient',
  pan: 'Numéro de séjour du patient',
  locationId: `ID (interne à l'établissement) de localisation du patient`,
};

export const translations = {
  age: 'Âge',
  fares: 'Missions',
  team: 'Équipe',
  password: 'mot de passe',
  areas: 'Services',
  seeMore: 'Voir plus',
  orderJourney: 'Commander le brancardage',
  delays: 'Retards',
  incident: 'Incident',
  years: 'ans',
  incidents: 'Incidents',
  incidentWithCount: (count: number) => `${count} ${count > 1 ? 'incidents' : 'incident'}`,
  anyIncidentsOnFare: 'Aucun incident',
  overview: "Vue d'ensemble",
  area: 'Service',
  delayCaused: 'Retard occasionné',
  moreThanOneHour: "Plus d'une heure",
  patientCallMessageStart: 'Vous vous apprêtez à appeler',
  patientCallMessageEnd:
    'Dès que vous aurez confirmé l’appel, la demande sera activée et un brancardier ira prendre en charge le patient dans les plus brefs délais',
  search: 'Rechercher',
  username: "Nom d'utilisateur",
  addFilter: '+ Ajouter un filtre',
  yes: 'Oui',
  no: 'Non',
  apply: 'Appliquer',
  fareFailed: 'Mission échouée',
  submit: 'Valider',
  canceled: 'Annulées',
  failed: 'Échouées',
  selectAReason: 'Choisissez un motif',
  saveIncident: "Enregistrer l'incident",
  statistics: 'Statistiques',
  addYourCommentHere: 'Entrez votre commentaire ici',
  login: 'Connexion',
  createIncident: 'Déclarer un incident',
  yesterday: 'Hier',
  todayShrink: 'Auj',
  today: "Aujourd'hui",
  hour: 'Heure',
  minutesShrinks: 'min',
  date: 'Date',
  by: 'Par',
  daysShrink: 'jrs',
  tomorrow: 'Demain',
  nextTick: 'Prochaine étape',
  fareTickUpdated: 'Mission mise à jour',
  createAnIncident: 'Créer un incident',
  toastNewIncident: (reference: string) => `Nouvel incident - Mission ${reference}`,
  toastFareFailed: (reference: string) => `Mission ${reference} échouée`,
  notificationCreated: (reference: string) => ({
    title: 'Nouvelle mission',
    body: `La mission ${reference} vient d'être créée`,
  }),
  notificationIncident: (reference: string) => ({
    title: 'Nouvel incident',
    body: `Un incident à été déclaré pour la mission ${reference}`,
  }),
  notificationFailed: (reference: string) => ({
    title: 'Mission échouée',
    body: `La mission ${reference} a échoué`,
  }),
  logout: 'Déconnexion',
  qrCode: 'QR Code',
  asSoonAsPossible: 'Dès que possible',
  qrCodeText: "QR code à scanner sur l'application",
  orderJourneyCTA: 'Commander un brancardage',
  sendRequest: 'Envoyer la demande',
  saveAsDraft: 'Enregistrer le brouillon',
  saveUpdates: 'Enregistrer les modifications',
  service: 'Service',
  selectArea: 'Sélectionnez une zone',
  selectService: 'Unité',
  reference: 'Ref',
  patient: 'Patient',
  employee: 'Agent',

  showFare: 'Voir la mission',
  toastNewFareRequest: 'Vous avez une nouvelle mission à traiter',
  toastNewFareInfo: 'Nouvelle mission reçue',
  faresPerPage: 'Missions par page',
  departure: 'Départ',
  destination: 'Destination',
  arrival: 'Arrivée',
  schedule: 'Rendez-vous',
  messageSuccessFareCreated: 'La mission a été créée',
  messageSuccessFareUpdated: 'La mission a été mise à jour',
  messageSuccessFareDraftCreated: 'La mission a été ajoutée dans les brouillons',
  messageSuccessHealthCenterSettingsUpdated: 'Les paramètres de l’établissement ont été mis à jour',
  fareCanceled: 'La mission a été annulée',
  incidentCreated: "L'incident a été créé",
  fareDoesntExist: "La mission n'existe pas",
  status: 'Statut',
  path: 'Trajet',
  porterOff: 'Brancardier non disponible',
  addPorter: 'Ajouter un agent',
  arrivalTime: "Heure d'arrivée",
  when: 'Quand ?',
  transport: 'Transport',
  cancel: 'Annuler',
  save: 'Enregistrer',
  creation: 'Création',
  confirm: 'Confirmer',
  submitAssignation: "Valider l'assignation",
  deleteAssignation: "Supprimer l'assignation",
  noPortersAssigned: 'Aucuns brancardiers assignés',
  delete: 'Supprimer',
  edit: 'Modifier',
  confirmCallPatient: "Confirmer l'appel",
  callPatient: 'Appeler le patient',
  pushPatient: "Déclencher l'appel",
  tooltipFareConfirmation: "Le service doit déclencher l'appel pour débuter la mission",
  selectARoom: 'Chambre, emplacement',
  noAreaAvailable: 'Aucun lieu pour ce service',
  reason: 'Motif',
  noFares: 'Aucunes missions',
  noComment: ' Pas de commentaire',
  comment: 'Commentaire',
  comments: 'Commentaires',
  noFaresActive: 'Aucunes missions active à afficher',
  noFaresPending: 'Aucunes missions en attente à afficher',
  noFaresAssigned: 'Aucunes missions assignée à afficher',
  noFaresInProgress: 'Aucunes missions en cours à afficher',
  noDraft: 'Aucuns brouillons à afficher',
  noFaresDone: 'Aucunes missions terminée à afficher',
  complement: 'Complément',
  porterAssigned: 'Brancardier assigné',
  meeting: 'Rendez-vous',
  infos: 'Infos',
  transportType: 'Mode de transport',
  porter: 'Brancardier',
  porters: 'Brancardiers',
  assign: 'Assigner',
  saveAndClose: 'Enregistrer et fermer',
  saveAndAssign: 'Enregistrer et assigner',
  addComment: 'Ajoutez un commentaire',
  departureService: 'Service de départ',
  arrivalService: "Service d'arrivée",
  subArea: 'Chambre, emplacement',
  firstName: 'Prénom',
  caution: 'Attention',
  modalCancelPushFare: {
    title: 'Annulation de sortie',
    body1: 'Vous vous apprêtez à annuler la sortie de',
    body2:
      'Dès que vous aurez annulé la demande de sortie, la demande sera annulée et les brancardiers seront désassignés de la prise en charge du patient',
    buttonConfirmation: 'Annuler la sortie',
  },
  modalConfirmPushFare: {
    title: "Déclenchement de l'appel",
    body1: "Vous vous apprêtez à déclencher l'appel pour le transport",
    body2:
      'Dès que vous aurez confirmé, la demande sera activée et un brancardier prendra en charge le patient dans les plus brefs délais',
    buttonConfirmation: "Confirmer l'appel",
  },
  modalConfirmPullFare: {
    title: 'Appel de patient',
    body1: 'Vous vous apprêtez à appeler',
    body2:
      'Dès que vous aurez confirmé l’appel, la demande sera activée et un brancardier ira prendre en charge le patient dans les plus brefs délais',
    buttonConfirmation: "Confirmer l'appel",
  },
  modalCancelPullFare: {
    title: "Annulation de l'appel",
    body1: "Vous vous apprêtez à annuler l'appel de",
    body2:
      "Dès que vous aurez annulé l'appel, la demande sera annulée et les brancardiers seront désassignés de la prise en charge du patient",
    buttonConfirmation: "Annuler l'appel",
  },
  successConfirmFare: "L'appel a bien été déclenché",
  lastName: 'Nom de famille',
  noServiceFound: 'Aucun service trouvé',
  noAreaFound: 'Aucun lieu trouvé',
  way: 'Moyen',
  pathTo: 'Vers',
  assignation: 'Assignation',
  actions: 'Actions',
  otherActions: 'Autres actions',
  allRequests: 'Toutes les demandes',
  cautionLeaveFareDetailsToastClicked: "Vous vous apprêtez à visualiser le détail d'une autre mission.",
  cautionLeaveEditFare:
    "Vous vous apprêtez à quitter l'assignation de la mission sans l'avoir validée. Vous risquez de perdre les modifications effectuées.",
  cautionDeleteFare: 'Vous vous apprêtez à annuler une mission. Cette action est irréversible.',
  cautionDeleteLastPorter:
    'Si vous supprimez ce brancardier, le statut de la mission passera de "Assigné" à "En Attente"',
  faresFilterActive: 'actives',
  faresFilterPending: 'À assigner',
  faresFilterInProgress: 'en cours',
  faresFilterDone: 'terminées',
  faresFilterDraft: 'Brouillons',
  faresFilterToConfirmIn: 'entrées',
  faresFilterToConfirmOut: 'sorties',
  faresDone: 'Missions terminées',
  faresAssigned: 'Missions assignées',
  noData: 'Aucune donnée disponible',
  faresArchived: 'Missions archivées',
  archives: 'Archives',
  roundTrip: 'Aller/retour',
  roundTripShrink: 'A/R',
  fare: 'Mission',
  outward: 'Aller',
  return: 'Retour',
  preciseSchedule: "Précisez l'heure du rendez-vous",
  noPortersAvailable: 'Aucun brancardier disponible',
  emergency: 'Urgence',
  teamPortersAll: 'Tous les agents',
  startedAt: 'Date de début de service',
  endedAt: 'Date de fin de service',
  teamPortersWorking: 'En service',
  teamPortersAvailable: 'Disponibles',
  teamPortersAssigned: 'Assignés',
  teamPortersBusy: 'En mission',
  noTeamPortersAll: 'Aucun agent à afficher',
  noTeamPortersWorking: 'Aucun agent en service à afficher',
  noTeamPortersAvailable: 'Aucun agent disponible à afficher',
  noTeamPortersAssigned: 'Aucun agent assigné à afficher',
  noTeamPortersBusy: 'Aucun agent en mission à afficher',
  lastArea: 'Dernière position connue',
  nextArea: 'Se dirige vers',
  fareStatus,
  farePosition: {
    label: 'Moyen de transport',
    ...farePosition,
  },
  incidentReasons,
  meetAt: 'RDV à',

  fareSpecificity,
  patientGender: {
    label: 'Civilité',
    ...patientGender,
  },
  patientGenderShrink,
  formErrors: {
    required: 'Ce champ est requis.',
    wrongDateFormat: 'Format incorrect (JJ/MM/AAAA)',
  },
  porterStatus,
  statsHeaders,
  filters,
  dateFilters,
  version: 'version',
  roles,
  globalError: 'Une erreur est survenue',
  clickToReload: 'Cliquez sur le bouton suivant pour recharger la plateforme',
  reload: 'Recharger',
  linkerParamsTrad,
  pathTimeDuration: 'Durée du trajet',
  pathTimeEstimation: 'Estimation',
  notDefined: 'non défini',
  cancelPullFare: "Annuler l'appel",
  cancelPushFare: "Annuler l'appel",
  notFilled: 'Non renseigné',
  noDelay: 'Aucun retard',
  deleteAssignationPatientInCare:
    'Le brancardier a déjà pris en charge le patient, il n’est plus possible d’annuler la mission',
  updateAssignationPatientInCare:
    'Le brancardier a déjà pris en charge le patient, il n’est plus possible de modifier la mission',
  updateAssignationFareStarted:
    'Le brancardier approche déjà le patient, il n’est plus possible de modifier la mission',
  confirmFareAssignationPatientInCare:
    'Le brancardier a déjà pris en charge le patient, il n’est plus possible d’annuler le transport à ce stade',
  fareDone: 'La mission est terminée. Il n’est plus possible de la modifier',
  fareDate: 'Date du transport',
  fareTime: "Heure d'arrivée souhaitée",
  anyPorterAssigned: 'Aucun brancardier assigné à cette mission',
  anyPorterToAssign: 'Aucun brancardier à assigner à cette mission',
  tablePorterAssigned: 'Brancardiers assignés à cette mission',
  faresGroup: 'Groupe de missions',
  portersLimitRecommendedByHealthCenter: (portersLimit: number, position: string) =>
    ` L'établissement recommande ${portersLimit} brancardier${
      portersLimit > 1 ? 's' : ''
    } pour un mode de transport ${position}`,
  tablePorterUnassigned: 'Ajouter un brancardier à cette mission',
  thereIsDate: (formattedDate: string) => `Il y a ${formattedDate}`,
  intoDate: (formattedDate: string) => `Dans ${formattedDate}`,
  porterFaresDoneAndAssigned: 'Missions terminées (Missions en cours et assignées)',
  ippShort: 'IPP',
  panShort: 'Séjour',
  location: 'Localisation',
  errorOccured: {
    title: 'Une erreur est survenue',
    body1: 'Si cette erreur persiste merci de vous rapprocher de votre service informatique.',
    body2: 'Pour continuer, vous pouvez actualiser la page.',
    button: 'Actualiser la page',
  },
  searchResultsCount: (count: number) => `${count} résultat${count > 1 ? 's' : ''}`,
  logoutPorter: 'Déconnexion',
  logoutPorterTooltip: 'Déconnecter le brancardier',
  cautionLogoutPorterAvailable: (firstName: string, lastName: string) =>
    `Êtes-vous sûr de vouloir déconnecter ${firstName} ${lastName} ?`,
  cautionLogoutPorterBusy: (firstName: string, lastName: string) =>
    `⚠️ Attention ${firstName} ${lastName} est déclaré(e) comme étant en mission. Êtes-vous sûr de vouloir le/la déconnecter ?`,
  helpRequired: 'Renforts demandés',
  adequateTransport: 'Mode de transport adapté',
  fareHistory: {
    headerTitle: `Voir l'historique de la mission`,
    errorMessage: `Impossible de récupérer l'historique`,
    fareCreated: 'Mission créée',
    fareCompleted: 'Mission terminée',
    fareStarted: 'Mission débutée',
    incidentReportedBy: (name?: string) => `Incident déclaré${name ? ` par ${name}` : ''}`,
    fareCanceledBy: (name?: string) => `Mission annulée${name ? ` par ${name}` : ''}`,
    fareAssignedTo: (name: string) => `Mission assignée à ${name}`,
    fareAcknowledgedBy: (name: string) => `Mission acquittée par ${name}`,
    fareCreatedBy: (name?: string) => `Mission créée${name ? ` par ${name}` : ''}`,
    patientCare: 'Patient pris en charge',
  },
  clear: 'Effacer',
  clearFilter: 'Effacer les filtres',
  areaFilterLabel: 'Filtrer par emplacement',
  areaLabel: 'Emplacements',
  noPlaceFound: 'Aucun emplacement trouvé',
  ins: 'INS',
  step: 'Étape',
  returnToStartingPoint: 'Retour au point de départ',
  fillStartingPointMessage: 'Veuillez renseigner le point de départ',
  urgent: 'Urgent',
  addJourneyStep: 'Ajouter une étape intermédiaire',
  journeyDetailsTitle: 'Voir le parcours complet',
  newJourney: 'Nouveau brancardage',
  editJourney: 'Modifier le brancardage',
  journeyEdit: 'Modification du brancardage',
  cancelJourney: 'Annuler le brancardage',
  journeyCreated: 'Brancardage créé',
  journeyUpdated: 'Brancardage mis à jour',
  inProgressStepIndicatorText: 'Trajet en cours ...',
  startFare: 'Débuter la mission',
  declarePatientCare: 'Déclarer la prise en charge',
  endFare: 'Terminer la mission',
  cancelJourneyModal: {
    title: 'Annuler le brancardage',
    content: [
      'Vous vous apprêtez à annuler un brancardage et la ou les mission(s) qui y sont associée(s).',
      'Attention, cette action est irréversible.',
    ],
    confirmLabel: 'Annuler le brancardage',
    cancelLabel: 'Retour',
  },
  cancelJourneySuccess: 'Le brancardage a été annulé',
  queuedFareContentText:
    'Cette mission n’est pas encore disponible. Vous pouvez voir la mission active en cliquant sur le bouton ci-dessous.',
  redirectToActiveFareLabel: 'Voir la mission active',
  settings: 'Paramètres',
} as const;
