import {
  AccommodationSpecificity,
  ExternalPatientTransportStatus,
  HealthcareEnvironmentalCleaningStatus,
  NotificationProvider,
  NotificationTriggerEvent,
  PatientGender,
  UnitType,
} from '@ambuliz/sabri-core';
import { format, isToday } from 'date-fns';
import { AccommodationType } from 'kurt/components/AddAccommodation/AddAccommodationForm';
import { AccommodationAction } from 'kurt/components/PatientFlowKanban/VisitActions';
import { ORANGE_THRESHOLD, OccupancyFilter, RED_THRESHOLD } from 'kurt/const';
import { MovementTypeFilter } from 'kurt/pages/Movements/useMovementsPageSearchParams';
import { UnitStatisticsType } from 'kurt/pages/Unit/UnitStatistics/UnitStatistics';

export type AccommodationRequestType = 'BED_MUTATION' | 'INTERNAL_MUTATION';
const accommodationSpecificities: Record<AccommodationSpecificity, string> = {
  ACCOMMODATED: 'Hébergé',
  ISOLATED: 'Isolement',
  ISOLATED_AIR: 'Isolement air',
  ISOLATED_DROPLET: 'Isolement gouttelettes',
  ISOLATED_CONTACT: 'Isolement contact',
  ISOLATED_PROTECTOR: 'Isolement protecteur',
  COVID: 'COVID',
  PRIVATE_ROOM: 'Chambre privative',
  WAITING_FOR_DOWNSTREAM_BED: 'Attente aval',
};

const movementTypes: Record<MovementTypeFilter, string> = {
  DIRECT_ADMISSION: 'Entrée directe',
  INTERNAL_MUTATION: 'Mutation interne',
  SCHEDULED_ADMISSION: 'Entrée programmée',
  SCHEDULED_DISCHARGE: 'Sortie programmée',
};

const bedStatuses = {
  AVAILABLE: 'Disponible',
  CLOSED: 'Fermé',
  BUSY: 'Occupé',
  CLEANING: 'Bionettoyage',
  BOOKED: 'Réservé',
  ...accommodationSpecificities,
};

const dateStatuses = {
  ESTIMATED: 'prévisionnelle',
  VALIDATED: 'confirmée',
};

const patientGenders: Record<PatientGender, string> = {
  FEMALE: 'Mme',
  MALE: 'M.',
  UNKNOWN: 'Inconnu',
};

const bedGenders: Record<PatientGender, string> = {
  FEMALE: 'Femme',
  MALE: 'Homme',
  UNKNOWN: 'Mixte',
};

const anomalyStatuses = { RESOLVED: 'Résolue', OPENED: 'Ouverte' };
const anomalyTypes = {
  OUTDATED_START: 'Entrée dépassée',
  OUTDATED_PREVISIONAL_END: 'Sortie prévisionnelle dépassée',
  OUTDATED_VALIDATED_END: 'Sortie confirmée dépassée',
  OVERLAPPING_STEPS: 'Chevauchement avec un autre séjour',
};

const unitType: Record<UnitType, string> = {
  ACCOMMODATION: 'Hébergement',
  EMERGENCY: 'Urgences',
  MEDICAL: 'Médical',
  OTHER: 'Autres',
};

const unitStatistics: Record<
  keyof Omit<UnitStatisticsType, 'TOTAL' | 'PRIVATE_ROOM' | 'ACCOMMODATED_OTHER_UNIT'>,
  string
> = {
  ACCOMMODATED: 'Hébergé',
  CLOSED: 'Fermés',
  AVAILABLE: 'Disponibles',
  ISOLATED: 'Isolement',
  ISOLATED_AIR: 'Air',
  ISOLATED_DROPLET: 'Gouttelettes',
  ISOLATED_CONTACT: 'Contact',
  ISOLATED_PROTECTOR: 'Protecteur',
  BUSY: 'Occupés',
  COVID: 'COVID',
  WAITING_FOR_DOWNSTREAM_BED: 'Attente aval',
  BOOKED: 'Réservés',
  CLEANING: 'Bionettoyage',
};

const externalTransportStatus: Record<ExternalPatientTransportStatus, string> = {
  PLANNED: 'Planifié',
  VALIDATED: 'Validé',
  IN_PROGRESS: 'En cours',
  DONE: 'Terminé',
  CANCELED: 'Annulé',
};

const healthCareEnvironmentalCleaningStatus: Record<HealthcareEnvironmentalCleaningStatus, string> = {
  PLANNED: 'Programmé',
  IN_PROGRESS: 'En cours',
  DONE: 'Terminé',
  CANCELED: 'Annulé',
};

const monthToLocale = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Aout',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

const occupancyFilters: Record<OccupancyFilter, string> = {
  LOWER_THAN_ORANGE_THRESHOLD: `Inférieur à ${ORANGE_THRESHOLD}%`,
  BETWEEN_ORANGE_THRESHOLD_AND_RED_THRESHOLD: `Entre ${ORANGE_THRESHOLD}% et ${RED_THRESHOLD}%`,
  GREATER_THAN_RED_THRESHOLD: `Supérieur à ${RED_THRESHOLD}%`,
};

const visitActions: Record<
  AccommodationAction,
  {
    action: { label: string };
    form: { title: string; question?: string; inputLabel?: string; content?: string[]; confirmLabel?: string };
    success: { title: string; message: string };
    error?: {
      inputError?: string;
      popupError?: string;
    };
  }
> = {
  SET_BED: {
    action: {
      label: 'Affecter un lit au patient',
    },
    form: {
      title: 'Affecter un lit au patient',
      question: 'Où souhaitez-vous placer le patient ?',
      confirmLabel: 'Affecter le lit',
    },
    success: {
      title: 'C’est fait !',
      message: 'Le lit a bien été affecté au patient.',
    },
    error: {
      popupError: "Erreur lors de l'affectation du lit",
    },
  },
  UPDATE_SPECIFICITIES: {
    action: {
      label: 'Modifier les spécificités',
    },
    form: {
      title: 'Modifier les spécificités',
      question: "Quelles sont les spécificités de l'hébergement à venir ?",
      inputLabel: 'Spécificités du lit',
      confirmLabel: 'Sauvegarder',
    },
    success: {
      title: "C'est noté !",
      message: "Les spécificités de l'hébergement à venir ont bien été mises à jour.",
    },
    error: {
      popupError: 'Erreur lors de la modification des spécificités',
    },
  },
  UPDATE_DATES: {
    action: {
      label: 'Modifier les dates',
    },
    form: {
      title: 'Modifier les dates',
      question: "Quelles sont les dates de l'hébergement à venir ?",
      confirmLabel: 'Sauvegarder',
    },
    success: {
      title: "C'est noté !",
      message: "Les dates de l'hébergement à venir ont bien été modifiées.",
    },
    error: {
      popupError: 'Erreur lors de la modification des dates',
    },
  },
  CANCEL_ADMISSION: {
    action: {
      label: 'Déprogrammer l’entrée du patient',
    },
    form: {
      title: 'Déprogrammer l’entrée',
      content: [
        'Souhaitez-vous vraiment déprogrammer l’entrée du patient ?',
        'Le séjour sera supprimé, cette action est irréversible.',
      ],
      confirmLabel: 'Oui, déprogrammer l’entrée',
    },
    success: {
      title: 'Ça marche !',
      message: 'L’entrée du patient a été déprogrammée.',
    },
    error: {
      popupError: "Erreur lors de la déprogrammation de l'entrée du patient",
    },
  },
  CONFIRM_ADMISSION: {
    action: {
      label: "Signaler l'entrée du patient",
    },
    form: {
      title: "Signaler l'entrée du patient",
      inputLabel: 'Date d’entrée',
      confirmLabel: 'Signaler l’entrée',
    },
    success: {
      title: 'C’est tout bon !',
      message: "L'entrée du patient a bien été signalée.",
    },
    error: {
      inputError: "Vous devez renseigner une date d'entrée valide",
      popupError: "Erreur lors de la déclaration de l'entrée du patient",
    },
  },
  UPDATE_BED: {
    action: {
      label: 'Modifier le lit affecté',
    },
    form: {
      title: 'Modifier le lit affecté au patient',
      question: 'Où souhaitez-vous placer le patient ?',
      confirmLabel: 'Modifier le lit',
    },
    success: {
      title: 'C’est fait !',
      message: 'Le lit a bien été affecté au patient.',
    },
    error: {
      popupError: 'Erreur lors de la modification du lit',
    },
  },
  UPDATE_UNIT: {
    action: {
      label: "Modifier l'unité affectée",
    },
    form: {
      title: "Modifier l'unité affectée",
      question: 'Dans quelle unité souhaitez-vous placer le patient ?',
      confirmLabel: 'Sauvegarder',
    },
    success: {
      title: 'Ça marche !',
      message: "L'unité affectée a bien été modifiée.",
    },
    error: {
      popupError: "Erreur lors de la modification de l'unité affectée",
    },
  },
  CANCEL_BED: {
    action: {
      label: 'Supprimer le lit affecté',
    },
    form: {
      title: 'Supprimer le lit affecté',
      content: [
        'Souhaitez-vous vraiment supprimer le lit affecté au patient ?',
        'Le séjour repassera dans la section “à placer”.',
      ],
      confirmLabel: 'Oui, supprimer le lit affecté',
    },
    success: {
      title: 'C’est fait !',
      message: 'Le lit affecté au patient a bien été supprimé.',
    },
    error: {
      popupError: 'Erreur lors de la suppression du lit affecté',
    },
  },
  SET_MUTATION_BED: {
    action: {
      label: 'Affecter un lit au patient',
    },
    form: {
      title: 'Affecter un lit au patient',
      question: 'Où souhaitez-vous placer le patient ?',
      confirmLabel: 'Affecter le lit',
    },
    success: {
      title: 'C’est fait !',
      message: 'Le lit a bien été affecté au patient.',
    },
    error: {
      popupError: "Erreur lors de l'affectation du lit",
    },
  },
  CANCEL_MUTATION_BED: {
    action: {
      label: 'Supprimer le lit affecté',
    },
    form: {
      title: 'Supprimer le lit affecté',
      content: [
        'Souhaitez-vous vraiment supprimer le lit affecté au patient ?',
        'Le séjour repassera dans la section “à placer”.',
      ],
      confirmLabel: 'Oui, supprimer le lit affecté',
    },
    success: {
      title: 'C’est fait !',
      message: 'Le lit affecté au patient a bien été supprimé.',
    },
    error: {
      popupError: 'Erreur lors de la suppression du lit affecté',
    },
  },
  VALIDATE_DISCHARGE_DATE: {
    action: {
      label: 'Confirmer la date de sortie',
    },
    form: {
      title: 'Confirmer la date de sortie',
      inputLabel: 'Date de sortie confirmée',

      confirmLabel: 'Confirmer la date',
    },
    success: {
      title: 'C’est noté !',
      message: 'La date de sortie du patient a été marquée comme confirmée.',
    },
    error: {
      popupError: 'Erreur lors de la confirmation de la date de sortie',
    },
  },
  INVALIDATE_DISCHARGE_DATE: {
    action: {
      label: 'Passer la date confirmée en date prévisionnelle',
    },
    form: {
      title: 'Passer la date confirmée en date prévisionnelle',
      inputLabel: 'Date de sortie prévisionnelle',
      confirmLabel: 'Confirmer la date',
    },
    success: {
      title: 'C’est noté !',
      message: 'La date confirmée de sortie a bien été passée en prévisionnelle.',
    },
    error: {
      popupError: 'Erreur lors de la modification du statut de la date de sortie',
    },
  },
  CANCEL_DISCHARGE: {
    action: {
      label: 'Déprogrammer la sortie du patient',
    },
    form: {
      title: 'Déprogrammer la sortie',
      content: [
        'Souhaitez-vous vraiment déprogrammer la sortie du patient ?',
        'La date de sortie du patient sera supprimée.',
      ],
      confirmLabel: 'Oui, déprogrammer la sortie',
    },
    success: {
      title: 'Ça marche !',
      message: 'La sortie du patient a été déprogrammée.',
    },
    error: {
      popupError: 'Erreur lors de la déprogrammation de la sortie du patient',
    },
  },
  DISCHARGE_PATIENT: {
    action: {
      label: 'Signaler la sortie effective',
    },
    form: {
      title: 'Signaler la sortie du patient',
      confirmLabel: 'Signaler la sortie effective',
      inputLabel: 'Date de sortie effective',
    },
    success: {
      title: 'C’est noté !',
      message: 'La date de sortie effective du patient a bien été définie.',
    },
    error: {
      popupError: 'Erreur lors de la déclaration de la sortie du patient',
    },
  },
  UPDATE_PLANNED_DISCHARGE_DATE: {
    action: {
      label: 'Modifier la date prévisionnelle de sortie',
    },
    form: {
      title: 'Modifier la date prévisionnelle de sortie',
      inputLabel: 'Date de sortie prévisionnelle',
      confirmLabel: 'Confirmer la date',
    },
    success: {
      title: 'C’est noté !',
      message: 'La date prévisionnelle de sortie du patient a bien été modifiée.',
    },
    error: {
      popupError: 'Erreur lors de la modification de la date prévisionnelle de sortie',
    },
  },
  UPDATE_VALIDATED_DISCHARGE_DATE: {
    action: {
      label: 'Modifier la date confirmée de sortie',
    },
    form: {
      title: 'Modifier la date confirmée de sortie',
      inputLabel: 'Date de sortie confirmée',
      confirmLabel: 'Confirmer la date',
    },
    success: {
      title: 'C’est noté !',
      message: 'La date confirmée de sortie du patient a bien été modifiée.',
    },
    error: {
      popupError: 'Erreur lors de la modification de la date confirmée de sortie',
    },
  },
  ACCEPT_INTERNAL_MUTATION_REQUEST: {
    action: {
      label: 'Donner mon accord de principe',
    },
    form: {
      title: 'Donner un accord de principe',
      inputLabel: 'Date et heure du mouvement',
      content: ['À quelle heure souhaitez-vous déplacer le patient ?'],
      confirmLabel: "Donner l'accord",
    },
    success: {
      title: 'Ça marche !',
      message: 'Votre accord de principe a bien été déclaré.',
    },
    error: {
      popupError: "Erreur lors de la déclaration de l'accord de principe",
    },
  },
  REJECT_INTERNAL_MUTATION_REQUEST: {
    action: {
      label: 'Refuser la mutation',
    },
    form: {
      title: 'Refuser la mutation',
      inputLabel: 'Motif du refus',
      content: ['Souhaitez-vous préciser le motif du refus ?'],
      confirmLabel: 'Refuser',
    },
    success: {
      title: 'Ça marche !',
      message: 'Vous avez refusé la demande de mouvement.',
    },
    error: {
      popupError: 'Erreur lors de la déclaration du refus de la demande de mouvement',
    },
  },
  EDIT_INTERNAL_MUTATION_PERFORMING_REQUEST: {
    action: {
      label: 'Modifier le fléchage',
    },
    form: {
      title: 'Modifier le fléchage',
      inputLabel: 'Unité fléchée',
      content: ['À quelle unité souhaitez-vous envoyer la demande ?'],
      confirmLabel: 'Modifier',
    },
    success: {
      title: 'Ça marche !',
      message: 'Le fléchage a bien été modifié.',
    },
    error: {
      popupError: 'Erreur lors de la modification du fléchage',
    },
  },
  ADDRESS_MUTATION_REQUEST: {
    action: {
      label: 'Flécher la demande',
    },
    form: {
      title: 'Flécher la demande',
      content: ['À quelle unité souhaitez-vous envoyer la demande ?'],
      confirmLabel: 'Envoyer la demande',
    },
    success: {
      title: 'Ça marche !',
      message: 'Le fléchage a bien été ajouté.',
    },
    error: {
      popupError: "Erreur lors de l'ajout du fléchage",
    },
  },
  ACCEPT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: {
    action: {
      label: "Déclarer l'accord",
    },
    form: {
      title: "Déclarer l'accord de principe",
      inputLabel: 'Date et heure du mouvement',
      content: ['À quelle heure souhaitez-vous déplacer le patient ?'],
      confirmLabel: "Déclarer l'accord",
    },
    success: {
      title: 'Ça marche !',
      message: "L'accord de principe par l'unité fléchée a bien été déclaré.",
    },
    error: {
      popupError: "Erreur lors de la déclaration de l'accord de principe",
    },
  },
  REJECT_INTERNAL_MUTATION_REQUEST_FROM_ORIGIN: {
    action: {
      label: 'Déclarer le refus',
    },
    form: {
      title: 'Déclarer le refus',
      inputLabel: 'Motif du refus',
      content: ['Souhaitez-vous préciser le motif du refus ?'],
      confirmLabel: 'Déclarer le refus',
    },
    success: {
      title: 'Ça marche !',
      message: "Le refus de la demande par l'unité fléchée a bien été déclaré.",
    },
    error: {
      popupError: 'Erreur lors de la déclaration du refus de la demande de mouvement',
    },
  },
  NEW_INTERNAL_MUTATION_REQUEST: {
    action: {
      label: 'Nouvelle proposition',
    },
    form: {
      title: 'Nouvelle proposition',
      inputLabel: 'À quelle unité souhaitez-vous envoyer la demande ?',
      confirmLabel: 'Envoyer la demande',
    },
    success: {
      title: 'Parfait !',
      message: 'La nouvelle proposition a bien été envoyée.',
    },
    error: {
      popupError: "Erreur lors de l'envoi de la nouvelle proposition",
    },
  },
  CANCEL_MUTATION_REQUEST: {
    action: {
      label: 'Supprimer la demande',
    },
    form: {
      title: 'Supprimer la demande',
      content: ['Souhaitez-vous vraiment supprimer cette demande ?', 'Cette action est irréversible.'],
      confirmLabel: 'Oui, supprimer la demande',
    },
    success: {
      title: 'Ça marche !',
      message: 'La demande de mouvement à bien été supprimée.',
    },
    error: {
      popupError: 'Erreur lors de la suppression de la demande de mouvement',
    },
  },
  UPDATE_MUTATION_DATE: {
    action: {
      label: "Modifier l'heure de montée",
    },
    form: {
      title: "Modifier l'heure de montée",
      inputLabel: 'Date et heure du mouvement',
      content: ['À quelle heure souhaitez-vous déplacer le patient ?'],
      confirmLabel: 'Sauvegarder',
    },
    success: {
      title: "C'est noté !",
      message: "L'heure de montée du patient a bien été modifiée.",
    },
    error: {
      popupError: "Erreur lors de la modification de l'heure de montée",
    },
  },
  REQUEST_MUTATION: {
    action: {
      label: 'Demander un mouvement',
    },
    form: {
      title: 'Demander un mouvement',
      inputLabel: 'À quelle unité souhaitez-vous envoyer la demande ?',
      confirmLabel: 'Envoyer la demande',
    },
    success: {
      title: 'Parfait !',
      message: 'La demande de mouvement a bien été envoyée.',
    },
  },
  CANCEL_ACCOMMODATION: {
    action: {
      label: "Supprimer l'hébergement",
    },
    form: {
      title: "Supprimer l'hébergement",
      content: ["Souhaitez-vous vraiment supprimer l'hébergement ?", 'Cette action est irréversible.'],
      confirmLabel: "Supprimer l'hébergement",
    },
    success: {
      title: 'Ça marche !',
      message: "L'hébergement du patient a bien été supprimé.",
    },
  },
  REOPEN_BED: {
    action: {
      label: 'Ouvrir et le rendre disponible',
    },
    form: {
      title: 'Ouvrir et le rendre disponible',
      content: ['Souhaitez-vous vraiment ouvrir et rendre disponible le lit ?'],
      confirmLabel: 'Oui, ouvrir et rendre disponible le lit',
    },
    success: {
      title: 'Ça marche !',
      message: 'Le lit a bien été ouvert et rendu disponible.',
    },
  },
  CANCEL_CLOSED_BED: {
    action: {
      label: 'Annuler la fermeture',
    },
    form: {
      title: 'Annuler la fermeture',
      content: ['Souhaitez-vous vraiment annuler la fermeture du lit ?'],
      confirmLabel: 'Oui, annuler la fermeture du lit',
    },
    success: {
      title: 'Ça marche !',
      message: 'La fermeture du lit a bien été annulée.',
    },
  },
  CLOSE_BED: {
    action: {
      label: 'Programmer la fermeture',
    },
    form: {
      title: 'Programmer la fermeture du lit',
      content: ['Souhaitez vous vraiment programmer la fermeture du lit ?'],
      confirmLabel: 'Oui, programmer la fermeture du lit',
    },
    success: {
      title: 'Ça marche !',
      message: 'La fermeture du lit a bien été programmée',
    },
  },
  ADD_ACCOMMODATION: {
    action: {
      label: 'Programmer un séjour',
    },
    form: {
      title: 'Programmer un séjour',
      content: ['Souhaitez vous vraiment programmer un séjour sur ce lit ?'],
      confirmLabel: 'Oui, programmer un séjour',
    },
    success: {
      title: 'Ça marche !',
      message: 'Un séjour a bien été créé sur ce lit.',
    },
  },
  VIA_TRAJECTOIRE_REDIRECT: {
    action: {
      label: 'Créer un dossier ViaTrajectoire',
    },
    form: {
      title: '',
    },
    success: {
      title: '',
      message: '',
    },
  },
};

const stepStatus = {
  NOT_CREATED: 'Non créé',
  TO_BE_PLACED: 'A placer',
  IN_PROGRESS: 'Patient admis',
  PLANNED: 'Lit affecté',
  COMPLETED: 'Terminé',
  CANCELLED: 'Annulé',
};

const mutationStepStatus = {
  IN_PROGRESS: 'En cours',
  COMPLETED: 'Terminé',
  PENDING: 'En attente',
  BLOCKED: 'Refusée',
  REFUSED: 'Refusée',
  ACCORD: 'Accord de principe',
  BED_ASSIGNED: 'Lit affecté',
  TO_ADDRESS: 'À flécher',
};

export const translations = {
  appTitle: {
    kurt: 'Silbo - Gestion des lits',
    sabri: 'Silbo - Brancardage',
    admin: 'Silbo - Administration',
  },
  login: 'Se connecter',
  logout: 'Déconnexion',
  username: "Nom d'utilisateur",
  password: 'Mot de passe',
  reporting: 'Reporting',
  ghtReporting: 'Reporting GHT',
  territorialReporting: 'Reporting territorial',
  occupancyRate: "Taux d'occupation",
  numberOfBeds: 'Nombre de lits',
  bedsTotal: 'Total des lits',
  emergencyOccupancy: {
    list: {
      key: 'Horaires',
      currentPatients: 'Présents aux urgences',
      waitingPatientsBySector: "Attente d'hospitalisation en",
      availableBeds: 'Lits disponibles en UHTCD',
      comment: 'Commentaire',
    },
    modal: {
      title: "Modifier l'occupation des urgences",
      adultsOccupancy: 'Occupation des urgences adultes',
      pediatricOccupancy: 'Occupation des urgences pédiatriques',
      currentPatients: 'Patients présents',
      availableBeds: 'Disponibilités en UHTCD',
      waitingPatientsBySector: "Attente d'hospitalisation en",
    },
    card: {
      title: 'Patients',
      present: 'Présents',
      averageWaitingTime: "Temps d'attente moyen",
      waitingPatients: 'En attente : ',
      currentPatients: 'Pris en charge : ',
    },
    toasts: {
      saveSuccess: "L'occupation des urgences à été enregistrée",
      saveError: "Une erreur s'est produite lors de l'enregistrement de l'occupation des urgences",
    },
  },
  management: 'Gestion',
  myUnit: 'Mon unité',
  myHealthCenter: 'Mon établissement',
  search: 'Rechercher',
  updatedAt: 'Mis à jour le ',
  exportData: 'Exporter les données',
  beds: 'lits',
  waitingForHospitalization: 'Attente hospitalisation',
  units: 'Unités',
  availableSpots: 'Disponible',
  admissions: 'Entrées',
  discharges: 'Sorties',
  myAdmissions: 'Mes entrées',
  next24: '+H24',
  next48: '+H48',
  date: 'Date',
  update: 'Mise à jour',
  updateShortened: 'Màj : ',
  patients: 'Patients',
  patientManagementTitle: 'Créer un patient',
  cancel: 'Annuler',
  covidAcceptance: 'Accueil COVID-19',
  bedsInventory: 'Inventaire des lits',
  bedsAvailable: 'Lits disponibles',
  save: 'Enregistrer',
  saveAlt: 'Sauvegarder',
  editUnitFromDivision: (unitName: string, divisionName: string) => `Éditer l'Unité ${unitName} - Pôle ${divisionName}`,
  unitToEdit: 'Unité à éditer',
  goBackToHealthCenter: "Retourner à l'établissement",
  edit: 'Éditer',
  create: 'Créer',
  requests: 'Demandes',
  isEditing: 'En édition',
  man: 'Homme',
  woman: 'Femme',
  indifferent: 'Indifférent',
  createChildArea: 'Créer une area enfant à',
  editArea: "Éditer l'area",
  createUnit: 'Créer une unité',
  editUnit: "Éditer l'unité",
  LYING_DOWN_BED: 'Lit',
  LYING_DOWN: 'Brancard',
  SITTING_UP: 'Assis',
  WALKING: 'Marche',
  unitType,
  MEDICAL: unitType.MEDICAL,
  ACCOMMODATION: unitType.ACCOMMODATION,
  OTHER: unitType.OTHER,
  EMERGENCY: unitType.EMERGENCY,
  merge: 'Fusionner',
  mergeConfirm: (name: string, selectedName?: string) =>
    `Fusionner l'area ${name} avec l'area ${selectedName} ? L'area ${selectedName} sera supprimée.`,
  mergeArea: (name: string) => `Fusionner l'area ${name}`,
  areaToMerge: 'Area à fusionner',
  hospital: 'Hôpital',
  unit: 'Unité',
  service: 'Service',
  room: 'Chambre',
  bed: 'Lit',
  active: 'Active',
  inactive: 'Inactive',
  suspended: 'Suspendue',
  parent: 'Parent',
  isSearchable: 'Recherchable',
  status: 'Statut',
  locations: 'Emplacements',
  appointments: 'Rendez-vous',
  externalTypeCode: 'Type externe',
  type: 'Type',
  clear: 'Effacer',
  specialty: 'Spécialité',
  specialties: 'Spécialités',
  defaultFarePositionFromThis: 'Position par défaut',
  externalId: 'Identifiant externe',
  externalIds: 'Identifiants externes',
  name: 'Nom',
  requestFareConfirmation: "Confirmation d'appel requise",
  sectors: 'Filières',
  sectorsTypes: {
    MED: 'Médecine',
    CHIR: 'Chirurgie',
    OBST: 'Chirurgie Obstétrique',
    PSY: 'Psychiatrie',
    SMR: 'SMR',
  },
  department: 'Pôle',
  departments: 'Pôles',
  qrCodeText: "QR code à scanner sur l'application",
  qrCode: 'QR code',
  version: 'version',
  close: 'Fermer',
  comingSoonTitle: 'Prochainement...',
  comingSoonText: ' 🚧  Cette fonctionnalité est en cours de développement 🚧 ',
  healthCenter: 'Établissement',
  healthCenters: 'Établissements',
  cancelEdition: 'Annuler les modifications',
  autodispatchActivated: 'Autodispatch activé',
  fareGroupActivated: 'Groupement de missions activé',
  fareGroupTimeInterval: 'Intervalle de temps (en minutes)',
  portersLimitByFarePosition: 'Limite de brancardiers par mission',
  portersLimitSittingUp: 'Position assise',
  portersLimitLyingDownBed: 'Position couchée - lit',
  portersLimitLyingDown: 'Position couchée - brancard',
  portersLimitWalking: 'Position debout',
  autodispatchTimeInMinutesAnticipation: "Temps d'anticipation (en minutes)",
  occupanciesAutoComputeShort: 'Calcul auto',
  occupanciesAutoCompute: 'Calcul auto des occupations',
  departmentOrUnit: 'Pôles | Unités',
  messageSuccessAreaUpdate: "L'emplacement a été modifié",
  messageSuccessAreaCreate: "L'emplacement a été créé",
  messageSuccessAreaMerge: 'Les emplacements ont été fusionnés',
  messageSuccessUnitUpdate: "L'unité a été modifiée",
  messageSuccessUnitCreate: "L'unité a été créée",
  messageErrorUnitAction: "Une erreur s'est produite",
  messageErrorAreaAction: "Une erreur s'est produite",
  healthCenterGroup: 'Groupement hospitalier',
  healthCenterGroupName: 'Nom du groupement',
  healthCenterName: 'Nom de l’établissement',
  noHealthCenterGroupSelected: 'Aucun groupement',
  messageSuccessHealthCenterUpdate: "L'établissement a été modifié",
  messageErrorHealthCenterUpdate: "Une erreur s'est produite",
  messageSuccessPatientCreate: 'Patient créé',
  messageErrorFetchingHealthCenterData: "Une erreur s'est produite",
  missingUnitPropError: 'Des propriétés sont manquantes',
  missingAreaPropError: 'Des propriétés sont manquantes',
  reCenterMap: 'Recentrer la carte',
  successMessageUnitUpdated: "L'unité a bien été mise à jour.",
  successTitleUnitUpdated: "C'est noté !",
  errorUnitUpdated: "Erreur lors de la mise à jour de l'unité.",
  openBeds: 'Lits ouverts',
  closedBeds: 'Lits fermés',
  maleAvailableBeds: 'Disponibles Homme',
  femaleAvailableBeds: 'Disponibles Femme',
  indifferentAvailableBeds: 'Disponibles indifférent',
  entriesDayD: 'Entrées Jour J',
  checkoutsDayD: 'Sorties Jour J',
  entriesH24: 'Entrées H+24',
  checkoutsH24: 'Sorties H+24',
  entriesH48: 'Entrées H+48',
  checkoutsH48: 'Sorties H+48',
  covid: 'COVID',
  poleView: 'Vue par Pôles',
  emergencyView: 'Vue service des Urgences',
  pole: 'Pôle',
  poles: 'Pôles',
  reset: 'Réinitialiser',
  hourPlus24: 'H+24',
  hourPlus48: 'H+48',
  movements: 'Mouvements',
  occupancy: 'Occupation',
  capacityRate: (rate: number) => `${rate}%`,
  totalOfBeds: (total: number) => ` /${total}`,
  waiting: ' en attente',
  ofWaiting: " d'attente",
  emergencies: 'Urgences',
  overview: 'Synthèse',
  reduce: 'Réduire',
  showDetail: 'Voir le détail',
  formatDateWithTime: (date: Date) => format(date, 'dd/MM/yyyy à HH:mm'),
  formatBirthdate: (date: Date) => format(date, 'dd/MM/yyyy'),
  formatTime: (date: Date) => format(date, 'HH:mm'),
  formatDate: (date: Date) => format(date, 'dd/MM/yyyy'),
  viewByHealthCenters: 'Vue par établissements',
  viewByEmergencies: 'Vue par services des Urgences',
  tension: 'Tension',
  users: 'Utilisateurs',
  createUser: 'Créer un utilisateur',
  updateVerb: 'Modifier',
  openBedAgain: 'Réouvrir le lit',
  updatePassword: 'Modifier le mot de passe',
  updateProfile: 'Modifier le profil',

  generateNewPassword: 'Générer un nouveau mot de passe',
  userLogin: 'Identifiant',
  userRole: 'Rôle',
  options: 'Options',
  confirm: 'Confirmer',
  copyToClipboard: 'Copier dans le presse-papier',
  copyToClipboardConfirm: 'Le texte a été copié dans le presse-papier',
  noted: 'Bien noté',
  newPasswordError: "Une erreur s'est produite lors de la génération du mot de passe",
  editUser: "Modifier l'utilisateur",
  MANAGER: 'Cadre / IDE',
  PORTER: 'Brancardier',
  ADMIN: 'Administrateur',
  REGULATOR: 'Régulateur',
  ADMIN_REGULATOR: 'Administrateur - Régulateur',
  BED_MANAGER: 'Gestionnaire de lits',
  porters: 'Brancardiers',
  missions: 'Missions',
  lastName: 'Nom de famille',
  firstName: 'Prénom',
  usernameHelper: "Si le nom d'utilisateur n'est pas renseigné, il en sera généré un automatiquement.",
  messageSuccessUserUpdate: "L'utilisateur a été modifié",
  messageSuccessUserCreate: "L'utilisateur a été créé",
  messageErrorUserAction: "Une erreur s'est produite",
  missingUserPropError: 'Des propriétés sont manquantes',
  unitContact: 'Contacts',
  addUnitContact: 'Ajouter un contact',
  invalidSession: 'Session invalide',
  errors: {
    'Invalid username/password.': 'Identifiant ou mot de passe invalide',
  } as any,
  yup: {
    requiredField: 'Ce champ est obligatoire',
  } as any,
  notFound: {
    title: "Oups, cette page n'existe pas !",
    content: 'Cliquez sur le bouton ci-dessous pour revenir en lieu sûr.',
    buttonLabel: 'Retour en lieu sûr',
  },
  areas: 'Emplacements',
  versionUpdateNotification: {
    toastMessage: 'Une nouvelle version du site est disponible !',
    modalTitle: 'Rechargement de la page',
    modalContent:
      'Vous allez recharger la page. Si vous avez commencé à remplir un formulaire vous allez perdre les informations non sauvegardées',
  },
  globalError: 'Une erreur est survenue',

  addNewAccommodation: 'Programmer un nouveau séjour',
  addAccommodationModal: {
    title: 'Programmer un séjour',
    closeBedsTitle: 'Programmer une fermeture',
    startDate: "Date d'entrée",
    endDate: 'Date de sortie',
    programmVisit: 'Programmer le séjour',
    programmAdmission: "Programmer l'entrée",
    confirmClosure: 'Programmer la fermeture',
    selectToggle: {
      patientStay: { label: 'Séjour patient', subTitle: 'Programmez un séjour patient dans un lit du service' },
      closingBed: { label: 'Fermeture de lits', subTitle: 'Programmez des fermetures de lit pour le bloquer' },
      scheduledAdmission: { label: 'Entrée programmée', subTitle: "Hospitalisation validée dans l'unité" },
      directAdmission: { label: 'Entrée directe', subTitle: 'Demande d’hospitalisation en provenance de l’extérieur' },
    },
    from: 'À partir du',
    to: "Jusqu'au",
    errors: {
      patient: 'Vous devez sélectionner un patient',
      bed: 'Vous devez sélectionner un numéro de lit',
      unit: 'Vous devez renseigner une unité',
      unitAndBed: 'Vous devez renseigner une unité et un numéro de lit',
      date: 'Vous devez renseigner une date',
      start: "Vous devez renseigner une date d'entrée",
      startClosed: 'Vous devez renseigner une date de début',
      end: 'Vous devez renseigner une date de sortie valide',
      endClosed: 'Vous devez renseigner une date de fin valide',
      directAdmissionOrigin: 'Vous devez renseigner une provenance',
    },
  },
  sendAccommodationRequestModal: {
    title: 'Faire une demande de mouvement',
    startDate: "Date d'entrée",
    endDate: 'Date de sortie',
    programmVisit: 'Programmer le séjour',
    programmDemand: 'Créer la demande',
    programmAdmission: "Programmer l'entrée",
    confirmClosure: 'Programmer la fermeture',
    selectToggle: {
      internal: { label: 'Changement de lit', subTitle: 'Changer le patient de lit au sein de l’unité' },
      internalHC: { label: 'Mutation interne', subTitle: 'Déplacer le patient dans une autre unité' },
    },
    from: 'À partir du',
    to: "Jusqu'au",
    errors: {
      patient: 'Vous devez sélectionner un patient',
      location: 'Vous devez sélectionner un numéro de lit',
      date: 'Vous devez renseigner une date',
      expectedAt: "Vous devez renseigner une date d'entrée",
      performerUnitIds: 'Vous devez renseigner au minimum un fléchage',
    },
  },
  sendAccommodationRequestSteps: (type: AccommodationRequestType) => ({
    accommodationRequestType: {
      label: 'Type de mouvement',
      activeLabel: 'Quel type de mouvement souhaitez-vous créer ?',
    },
    patientChoice: { label: 'Patient', activeLabel: 'Quel patient souhaitez-vous déplacer ?' },
    specificities: {
      label: 'Spécificités',
      activeLabel: 'Faut-il prévoir des spécificités pour ce mouvement ?',
    },
    date: {
      label: 'Date du mouvement',
      activeLabel: 'À partir de quelle heure souhaitez-vous déplacer le patient ?',
      expectedAt: 'Date et heure du mouvement',
    },
    guidance: {
      label: 'Fléchage',
      activeLabel: 'Où souhaitez-vous déplacer le patient ?',
    },
    location: {
      label: 'Choix du lit',
      activeLabel: 'Où souhaitez-vous déplacer le patient ?',
    },
    reason: {
      label: 'Motif du mouvement',
      activeLabel: 'Quel est le motif du mouvement ?',
      placeHolder: 'Motif du mouvement...',
    },
    comment: {
      label: 'Commentaire',
      activeLabel: 'Souhaitez vous ajouter un commentaire ?',
      placeHolder: 'Commentaire...',
    },
  }),
  unplannedDischarge: 'Sortie non planifiée',
  addReopeningDate: 'Ajouter une date de réouverture',
  unplannedReopening: 'Réouverture non planifiée',
  addDischargeDate: 'Ajouter une date de sortie',
  invalidDateFormat: 'Format de date invalide',
  ageYears: 'ans',
  ageMonths: 'mois',
  exit: { default: 'Sortie', planned: 'Sortie prévue' },
  days: 'jours',
  hours: 'heures',
  hour: 'heure',
  minutes: 'minutes',
  minute: 'minute',
  noNextAction: "Pas d'action prévue",
  duration: {
    short: {
      label: 'Durée',
      minutes: (minutes: number) => `${minutes} min`,
      hours: (hours: number) => `${hours} h`,
      days: (days: number) => (days > 1 ? `${days} jours` : `${days} jour`),
    },
    long: {
      label: 'Durée du séjour',
      minutes: (minutes: number) => `${minutes} min`,
      hours: (hours: number) => (hours > 1 ? `${hours} heures` : `${hours} heure`),
      days: (days: number) => (days > 1 ? `${days} jours` : `${days} jour`),
    },
  },
  totalDuration: 'Durée totale',
  bedStatuses,
  dateStatuses,
  currentHour: 'Heure actuelle',
  today: "Aujourd'hui",
  day: 'Jour',
  tomorrow: 'Demain',
  week: 'Semaine',
  bedStatus: 'Statut du lit',
  currentBedStatus: 'Statut actuel du lit',
  accommodationWarning: {
    startDateOutdated: {
      singular: 'entrée prévisionnelle dépassée',
      plural: 'entrées prévisionnelles dépassées',
    },
    endDateOutdated: {
      previsional: {
        singular: 'sortie prévisionnelle dépassée',
        plural: 'sorties prévisionnelles dépassées',
      },
      validated: {
        singular: 'sortie confirmée dépassée',
        plural: 'sorties confirmées dépassées',
      },
    },
    bedOverlapped: 'séjours se chevauchent dans ce lit',
    stepsOverlapped: { singular: 'chevauchement dans ce lit', plural: 'chevauchements dans ce lit' },
  },
  bedHasSomeOutdatedAccommodations: 'Un séjour dans ce lit dépasse sa date de sortie définie',
  bedHasCurrentOutdatedAccommodation: 'Ce séjour dépasse la date de sortie définie',
  bedHasOverlapedAccommodations: 'Certains séjours dans ce lit se chevauchent',
  unitView: (view: 'timeline' | 'grid' | 'list') =>
    view === 'timeline' ? 'Vue planning' : view === 'list' ? 'Vue liste' : 'Vue cartes',
  territorialView: (view: 'map' | 'list') => (view === 'map' ? 'Vue carte' : 'Vue liste'),
  bedSpecificities: {
    title: 'Spécificités du lit',
    options: accommodationSpecificities,
    select: {
      value: (number: number) =>
        number > 1 ? `${number} spécificités sélectionnées` : `${number} spécificité sélectionnée`,
      empty: 'Pas de spécificité',
    },
  },
  clearFilters: 'Effacer les filtres',
  bedsCount: (number: number) => (number > 1 ? `${number} lits` : `${number} lit`),
  previsionalAdmissionShort: 'Entrée prév.',
  previsionalAdmission: 'Entrée prévisionnelle',
  effectiveAdmission: 'Entrée effective',
  admission: 'Entrée',
  validatedDischarge: 'Sortie confirmée',
  validatedDischargeShort: 'Sortie conf.',
  effectiveDischarge: 'Sortie effective',
  previsionalDischarge: 'Sortie prévisionnelle',
  previsionalDischargeShort: 'Sortie prév.',
  previsionalMutationSort: 'Mutation prév.',
  effectiveMutation: 'Mutation effective',
  validatedMutation: 'Mutation validée',
  previsionalShort: 'Prév.',
  effectiveShort: 'Eff.',
  validatedShort: 'Conf.',
  discharge: 'Sortie',
  dischargedPatient: 'Patient sorti',
  admittedPatient: 'Patient admis',
  affectedBed: 'Lit affecté',
  toPlace: 'À placer',
  mutation: {
    reason: 'Motif du mouvement',
  },
  visitCount: (number: number) => (number > 1 ? `${number} séjours` : `${number} séjour`),
  requestsCount: (number: number) => (number > 1 ? `${number} demandes` : `${number} demande`),
  nextAccomodation: 'Prochain séjour',
  validate: 'Valider',
  accommodationUpdatedSuccess: { title: 'Ça marche !', message: 'Les dates du séjour ont bien été modifiées.' },
  accommodationUpdatedError: 'Erreur lors de la modification des dates du séjour.',
  accommodationCommentUpdatedSuccess: { title: 'Ça marche !', message: 'Le commentaire a bien été modifié.' },
  accommodationCommentUpdatedError: 'Erreur lors de la modification du commentaire.',
  futureAccommodationCancelledSuccess: {
    title: "C'est noté !",
    message: 'Le séjour a bien été déprogrammé. Le créneau est maintenant disponible.',
  },
  futureAccommodationCancelledError: 'Erreur lors de la déprogrammation du séjour.',
  accommodationCancelledSuccess: {
    title: "C'est noté !",
    message: 'Le séjour a bien été supprimé. Le créneau est maintenant disponible.',
  },
  accommodationCancelledError: 'Erreur lors de la suppression du séjour.',
  accommodationPatientDischargedSuccess: {
    title: "C'est noté !",
    message: 'La sortie du patient a été enregistrée, le lit est de nouveau disponible.',
  },
  accommodationPatientDischargedError: 'Erreur lors de la déclaration de sortie du patient.',
  accommodationBedClosedCancelledSuccess: {
    title: "C'est noté !",
    message: 'Le lit a bien été réouvert, il est de nouveau disponible.',
  },
  accommodationBedClosedCancelledError: 'Erreur lors de la réouverture du lit.',
  accommodationAddedSuccess: {
    title: 'Parfait !',
    message: 'Le séjour a bien été programmé.',
  },
  accommodationAddedError: 'Erreur lors de la programmation du séjour.',
  accommodationBedCloseAddedSuccess: {
    title: "C'est tout bon !",
    message: 'La fermeture du lit à bien été programmée.',
  },
  accommodationBedCloseAddedError: 'Erreur lors de la programmation de la fermeture du lit.',
  accommodationBedUpdatedSuccess: {
    title: "C'est fait",
    message: 'Le lit affecté au patient a bien été modifié.',
  },
  accommodationBedUpdatedError: 'Erreur lors de la modification du lit affecté au patient.',
  accommodationBedSpecificitiesUpdatedSuccess: {
    title: 'Ça marche !',
    message: 'Les spécificités du séjour ont bien été modifiées.',
  },
  accommodationBedSpecificitiesUpdatedError: 'Erreur lors de la modification des spécificités du séjour.',
  patientAdmittedReportSuccess: {
    title: "C'est noté !",
    message: "L'entrée du patient a été enregistrée. Le lit est maintenant occupé.",
  },
  patientAdmittedReportError: "Erreur lors de la déclaration d'entrée du patient.",
  location: 'Localisation',
  reportPatientAdmitted: {
    title: "Signaler l'entrée du patient",
    confirmLabel: "Signaler l'entrée",
    content: [
      'Souhaitez-vous signaler l’entrée du patient dans ce lit ? ',
      'La date d’entrée confirmée du patient sera définie à l’heure actuelle.',
    ],
    actionLabel: "Signaler l'entrée du patient",
  },
  updateBed: {
    title: 'Modifier le lit affecté au patient',
    confirmLabel: 'Modifier le lit',
    content: ['Où souhaitez-vous placer le patient ?'],
    actionLabel: 'Modifier le lit affecté au patient',
  },
  reportPatientDischarged: {
    title: 'Signaler le patient sorti',
    confirmLabel: 'Signaler la sortie effective',
    content: [
      'Souhaitez-vous signaler la sortie du patient de ce lit ?',
      'La date de sortie confirmée du patient sera définie à l’heure actuelle.',
    ],
    actionLabel: 'Signaler la sortie effective',
  },
  makeBedAvailable: {
    title: 'Réouvrir le lit',
    content: ['Souhaitez-vous réouvrir le lit et le rendre de nouveau disponible ?'],
    confirmLabel: 'Réouvrir le lit',
    actionLabel: 'Ouvrir le lit et le rendre disponible',
  },
  createExternalFare: 'Commander un transport sanitaire',
  createInternalFare: 'Commander un brancardage',
  cancelBedClosed: {
    title: 'Annuler la fermeture',
    content: ['Souhaitez-vous vraiment annuler la fermeture de ce lit ?', 'Cette action est irréversible.'],
    confirmLabel: 'Oui, annuler la fermeture',
    actionLabel: 'Annuler la fermeture',
  },
  cancelAccommodation: {
    title: "Supprimer l'hébergement",
    content: ["Souhaitez-vous vraiment supprimer l'hébergement ?", 'Cette action est irréversible.'],
    confirmLabel: "Oui, annuler l'hébergement",
    actionLabel: "Supprimer l'hébergement",
  },
  requestMovement: {
    title: 'Demander un mouvement',
    actionLabel: 'Demander un mouvement',
  },
  available: 'Disponible',
  availableAgain: 'De nouveau disponible le',
  unitStatistics,
  monthToLocale,
  dataExport: 'Export de données',
  dataExportTitle: 'Générer un export des missions de brancardage',
  dataExportSuccess: "Fichier d'export créé",
  commentInput: {
    label: 'Commentaire',
    placeholder: 'Ajoutez ici votre commentaire',
  },
  notData: 'Pas de donnée',
  unitEditForm: {
    informations: {
      title: 'Informations du service',
      beds: {
        open: 'Lits ouverts',
        close: 'Lits fermés',
      },
      patients: {
        accomodated: 'Patients hébergés',
        covid: 'Patients COVID-19',
        waitingForDownstreamBed: "En attente d'aval",
      },
    },
    inputOutput: {
      title: 'Planning des entrées / sorties',
      input: 'Entrées jour J',
      output: 'Sorties jour J',
    },
    availabilities: {
      title: 'Disponibilités',
      labels: {
        male: 'Homme',
        female: 'Femme',
        indifferent: 'Mixte',
      },
      dDay: 'Lits dispos',
      h24: 'Lits J+1',
      h48: 'Lits J+2',
    },
  },
  lastCommentUpdate: {
    long: (date: Date) => `Dernière modification le ${format(date, 'dd/MM/yyyy à HH:mm')}`,
    short: (date: Date) => `Le ${format(date, 'dd/MM à HH:mm')}`,
  },
  goBack: 'Retour',
  phoneNumber: 'Numéro de téléphone',
  contactName: 'Nom du contact',
  accomotadedPatients: 'Patients hébergés',
  details: 'Détails',
  availibility: 'Disponibilité',
  availableInUSC: 'Dispos en USC',
  availableInUSI: 'Dispos en USI',
  availableInREA: 'Dispos en Réa',
  covidOccupancy: 'Occupation COVID-19',
  patientTotal: 'Total patients',
  covidOccupancyDetails: "Détails de l'occupation COVID-19",
  waitingForDownstreamBedDetails: 'Détails des patients en attente d’aval',
  accommodatedDetails: 'Détails des patients hébergés',
  covidPatients: 'Patients COVID-19',
  availablesPlaces: 'Places disponibles',
  globalOccupancyRate: "Taux d'occupation global",
  waitingForDownstreamBed: "En attente d'aval",
  availableBeds: 'Lits disponibles',
  availableBedsShort: 'Lits dispos',
  availableBedsH24: 'Lits J+1',
  availableBedsH48: 'Lits J+2',
  bedsMale: 'Lits hommes',
  bedsFemale: 'Lits femmes',
  bedsIndifferent: 'Lits mixtes',
  listView: 'Vue liste',
  mapView: 'Vue carte',
  noResult: 'Pas de résultat',
  noPatientFound: 'Vous ne trouvez pas votre patient ?',
  notSavedModal: {
    title: 'Ne partez pas trop vite !',
    content: 'Vous avez des modifications non sauvegardées. Souhaitez-vous les enregistrer ?',
    cancel: 'Ne pas enregistrer',
    confirm: 'Enregistrer',
  },
  ...occupancyFilters,
  sectorOccupancy: 'Occupation par fillières',
  nbOfDepartments: (result: number) => `${result} ${result > 1 ? 'pôles' : 'pôle'}`,
  nbOfUnits: (result: number) => `${result} ${result > 1 ? 'unités' : 'unité'}`,
  outdatedUnit: "unité n'est plus à jour",
  outdatedUnits: 'unités ne sont plus à jour',
  bedNumber: 'N° du lit',
  bedNumbers: 'N° des lits',
  since: 'Depuis',
  bedMale: 'Lit homme',
  bedFemale: 'Lit femme',
  bedIndifferent: 'Lit mixte',
  step: 'Étape',
  previousStep: 'Étape précédente',
  nextStep: 'Étape suivante',
  createHospitalizationRequestForm: {
    patientStep: { label: 'Patient', activeLabel: 'Quel patient souhaitez-vous placer ?' },
    reasonStep: {
      label: `Motif d'hospitalisation`,
      activeLabel: `Quel est le motif d'hospitalisation ?`,
    },
    specificitiesStep: {
      label: 'Spécificités',
      activeLabel: 'Faut-il prévoir des spécificités pour ce séjour ?',
    },
    commentStep: {
      label: 'Commentaire',
      activeLabel: 'Souhaitez vous ajouter un commentaire ?',
      placeHolder: 'Commentaire...',
    },
    destinationStep: {
      label: 'Fléchage',
      setToAddress: 'Adresser sans fléchage',
      activeLabel: 'Où souhaitez-vous placer le patient ?',
    },
  },

  addAccommodationSteps: (type: AccommodationType) => ({
    accommodationType: { label: 'Type de séjour', activeLabel: 'Quel type de séjour souhaitez-vous créer ?' },
    patientChoice: { label: 'Patient', activeLabel: 'Quel patient souhaitez-vous placer ?' },
    specificities: {
      label: 'Spécificités',
      activeLabel:
        type === 'BED_CLOSURE'
          ? 'Faut-il prévoir des spécificités pour cette fermeture ?'
          : 'Faut-il prévoir des spécificités pour ce séjour ?',
    },
    dates: {
      label: 'Dates',
      activeLabel:
        type === 'BED_CLOSURE' ? 'Quelles sont les dates de fermeture ?' : 'Quelles sont les dates du séjour ?',
      startAt: type === 'BED_CLOSURE' ? 'À partir du' : 'Date d’entrée',
      endAt: type === 'BED_CLOSURE' ? "Jusqu'au" : 'Date de sortie',
    },
    bedChoice: {
      label: type === 'BED_CLOSURE' ? 'Choix des lits' : 'Choix du lit',
      activeLabel:
        type === 'BED_CLOSURE' ? 'Quel·s lit·s souhaitez-vous fermer ?' : 'Où souhaitez-vous placer le patient ?',
    },
    unitChoice: {
      label: "Choix de l'unité",
      activeLabel: 'Où souhaitez-vous placer le patient ?',
    },
    comment: {
      label: 'Commentaire',
      activeLabel: 'Souhaitez vous ajouter un commentaire ?',
      placeHolder: 'Commentaire...',
    },
  }),
  createAdmission: {
    title: 'Créer une entrée',
    steps: {
      requestType: { label: 'Type de séjour', activeLabel: "Quel type d'entrée souhaitez-vous créer ?" },
      patientChoice: { label: 'Patient', activeLabel: 'Quel patient souhaitez-vous placer ?' },
      specificities: {
        label: 'Spécificités',
        activeLabel: 'Faut-il prévoir des spécificités pour ce séjour ?',
      },
      dates: {
        label: 'Dates',
        activeLabel: 'Quelles sont les dates du séjour ?',
        startAt: 'Date d’entrée',
        endAt: 'Date de sortie',
      },
      unitChoice: {
        label: "Choix de l'unité",
        activeLabel: 'Où souhaitez-vous placer le patient ?',
      },
      comment: {
        label: 'Commentaire',
        activeLabel: 'Souhaitez vous ajouter un commentaire ?',
        placeHolder: 'Commentaire...',
      },
      directAdmissionOrigin: {
        label: 'Provenance',
        activeLabel: 'Quelle est la provenance du patient ?',
        placeholder: 'Émetteur de la demande...',
      },
      reason: {
        label: 'Motif de mouvement',
        activeLabel: 'Quel est le motif de mouvement ?',
      },
    },
  },
  accommodationSpecificities,
  until: 'Jusqu’au',
  createPatientForm: {
    title: 'Création manuelle d’un patient',
    helperText:
      'Avant de créer un patient manuellement, assurez-vous bien qu’il soit introuvable dans la base (nom de jeune fille, IPP, numéro de séjour, ...)',
    linkLabel: 'Créez-en un manuellement',
    buttonLabel: 'Créer un patient',
    birthdate: 'Date de naissance',
    pan: 'N° de séjour (optionnel)',
    ipp: 'IPP (optionnel)',
    successMessage: { title: 'Ça marche !', message: 'La patient a bien été créé,' },
  },
  formErrors: {
    required: 'Ce champ est requis',
    wrongDateFormat: 'La date doit être au format jj/mm/aaaa (ex : 01/01/2023)',
  },
  patientGenders: { ...patientGenders },
  patientFlowPageTitle: (view: 'kanban' | 'list' | 'sheets', flow: 'admission' | 'discharge', date: Date) =>
    `${view === 'sheets' ? 'Mouvements' : flow === 'admission' ? 'Entrées' : 'Sorties'} de l'unité ${
      isToday(date) ? 'aujourd’hui' : 'le ' + format(date, 'dd/MM')
    }`,
  planNewAdmission: 'Programmer une entrée',
  sendNewAccommodationRequest: 'Faire une demande de mouvement',
  planNewAdmissionSuccessToast: {
    title: 'Parfait !',
    admissionMessage: "L'entrée du patient a bien été programmée.",
    accommodationMessage: 'Le séjour du patient a bien été programmé.',
    externalAccommodationRequestMessage: 'La demande de mouvement à bien été créée.',
  },
  planNewBedClosureSuccessToast: {
    title: "C'est tout bon !",
    oneBedMessage: 'La fermeture de lit a bien été programmée',
    multipleBedMessage: 'La fermeture des lits a bien été programmée.',
  },
  planNewAdmissionError: 'Erreur lors de la programmation',
  patientSearchPlaceholder: 'Nom de famille, IPP, numéro de séjour...',
  chooseAnOtherPatient: 'Choisir un autre patient ...',
  moreActions: 'Actions supplémentaires',
  externalActions: 'Actions externes',
  visitActionsTitle: 'Actions',
  visitActions,
  dateNow: 'Date et heure actuelles',
  perfect: 'Parfait',
  dialogError: {
    title: "Oups, c'est gênant...",
    messageAdmission: 'Nous n’avons pas réussi à programmer l’entrée du patient pour une raison qui nous échappe.',
    messageMovement: 'Nous n’avons pas réussi à créer la demande de mouvement pour une raison qui nous échappe.',
    messageStay: 'Nous n’avons pas réussi à programmer le séjour patient pour une raison qui nous échape.',
    contactUsFirstPart: "Vous pouvez réessayer. N'hésitez pas à ",
    contactUsLastPart: " si l'erreur persiste.",
    contactUsLink: 'nous contacter',
  },
  noAdmissionToday: 'Aucun patient admis à ce jour',
  noDischargeToday: 'Aucun patient sorti ce jour',
  noStayWaitingForAdmission: 'Aucune entrée en attente',
  noValidatedDischarge: 'Aucune sortie confirmée actuellement',
  noStayToBePlaced: 'Aucune entrée prévue pour l’instant',
  noPlannedDischarge: "Aucune sortie prévue pour l'instant",
  nothingToSignal: 'Rien à signaler',
  itsCalmForNow: "Pour l'instant, c'est calme !",
  everythingIsCalmHere: 'Tout est calme par ici !',
  certitudeCitation: 'La seule certitude, c’est que rien n’est certain',
  thatsAllForNow: "C'est tout, pour le moment !",
  nothingAtTheHorizon: "Rien à l'horizon",
  patientGender: 'Sexe du patient',
  waitingForDownstream: "Attente d'aval",
  settings: 'Paramètres',
  fareList: 'Liste des missions',
  team: 'Équipe',
  territory: 'Territoire',
  sabri: 'Brancardage',
  kurt: 'Gestion des lits',
  in: 'Dans',
  myAccount: 'Mon compte',
  admin: 'Administration',
  goBackFromLabs: 'Retour sur silbo',
  careUnit: 'Unité de soins',
  notifications: 'Notifications',
  notProvided: 'Non renseigné',
  born: 'née',
  commentForm: {
    label: 'Commentaire',
    placeholder: 'Commentaire...',
    empty: 'Pas de commentaire.',
  },
  entryType: {
    label: "Type d'entrée",
    planned: 'Programmée',
    emergency: 'Urgences',
    internalTransfer: 'Transfert interne',
    externalTransfer: 'Transfert externe',
  },
  dischargeType: {
    label: 'Type de sortie',
    rad: 'Sortie - RAD',
    internalTransfer: 'Transfert interne',
    externalTransfer: 'Transfert externe',
  },
  noPendingRequest: 'Aucune demande en attente',
  stepStatus,
  mutationStepStatus,
  notificationCard: {
    markAsRead: 'Marquer comme lu',
    markAsUnread: 'Marquer comme non lu',
  },
  notificationsPanel: {
    title: 'Notifications',
    newAlerts: 'Nouvelles alertes',
    markAllAsRead: 'Tout marquer comme lu',

    history: 'Historique',
    empty: {
      title: 'On dirait que tout est à jour ici !',
      message: 'Revenez plus tard',
    },
  },
  requiredSpecificities: 'Spécificités demandées',
  movementStatus: 'Statut du mouvement',
  requestReceivedAt: 'Demande reçue le',
  bedChange: 'Changement de lit',
  movementType: 'Type de mouvement',
  movementReason: 'Motif du mouvement',
  comment: 'Commentaire',
  comments: 'Commentaires',
  accommodationStatus: "Statut de l'hébergement",
  specificities: 'Spécificités',
  departure: 'Départ',
  arrival: 'Arrivée',
  errorReadingAllNotifications: "Erreur lors de l'action Tout marquer comme lu",
  errorReadingNotification: "Erreur lors de l'action Marquer comme lu",
  errorUnreadingNotification: "Erreur lors de l'action Marquer comme non lu",
  notificationContent: {
    NEW_FARE_INCIDENT: {
      newIncidentCreatedBy: 'Un incident a été déclaré par',
      onPatientFare: 'sur la mission de',
      regulation: 'la régulation',
    },
    PORTER_START_OF_SERVICE: 'vient de démarrer son service',
    PORTER_END_OF_SERVICE: 'vient de terminer son service',
    NEW_PERFORMER_REQUEST: {
      requestForPatient: 'Nouvelle demande de mutation pour',
      fromUnit: 'en provenance de',
    },
    PERFORMER_REQUEST_ACCEPTED: {
      mutationOf: 'La mutation de',
      toUnit: 'en',
      wasValidatedFor: 'a été validée pour',
    },
    PERFORMER_REQUEST_REJECTED: {
      mutationOf: 'La mutation de',
      fromUnit: 'en provenance de',
      wasRejected: 'a été réfusée par',
    },
  },
  notificationsSettings: 'Paramètres de notifications',
  noNotificationConfigurations: "Par défaut l'utilisateur reçoit toutes les notifications liées à son rôle",
  disableAllNotifications: 'Désactiver toutes les notifications',
  disableNotification: 'Désactiver la notification',
  notificationTriggerEvents: {
    FARE_ASSIGNED: 'Mission assignée',
    FARE_UNASSIGNED: 'Mission désassignée',
    FARE_UPDATED: 'Mission modifiée',
    PORTER_SUPPORT_ASSIGNED: 'Renfort assigné',
    PORTER_SUPPORT_UNASSIGNED: 'Renfort désassigné',
    FARE_READY_TO_BE_ASSIGNED: 'Mission prête à être assignée',
    NEW_FARE_INCIDENT: 'Nouvel incident sur une mission',
    PORTER_START_OF_SERVICE: "Début de service d'un brancardier",
    PORTER_END_OF_SERVICE: "Fin de service d'un brancardier",
    NEW_PERFORMER_REQUEST: 'Nouvelle demande de mutation',
    PERFORMER_REQUEST_ACCEPTED: 'Mutation acceptée',
    PERFORMER_REQUEST_REJECTED: 'Mutation refusée',
  } as Record<NotificationTriggerEvent, string>,
  newNotificationConfig: 'Nouvelle configuration de notification',
  triggerEvent: 'Événement déclencheur',
  notificationIsAcknowledgeNeeded: 'Activer le rejeu de la notification',
  retryPolicy: 'Politique de réessai',
  retryDelay: 'Délai de réessai',
  retryMaxAttempts: 'Nombre maximum de tentatives',
  platforms: 'Plateformes',
  notificationProviders: {
    WEB: 'Silbo web',
    IN_APP: 'Silbo mobile',
    PUSH_MOBILE: 'Notification push (mobile)',
  } as Record<NotificationProvider, string>,
  inSeconds: 'en secondes',
  justNow: "À l'instant",
  yesterday: 'Hier',
  dayBeforeYesterday: 'Avant-hier',
  mutationSummary: {
    movementType: {
      INTERNAL_MUTATION: 'Mutation interne',
      BED_MUTATION: 'Changement de lit',
      DIRECT_ADMISSION: 'Entrée directe',
    },
  },
  guidance: 'Fléchage de la demande',
  guidanceStatus: {
    REQUESTED: 'En attente',
    REJECTED: 'Refus',
    CANCELLED: 'Annulé',
    ACCEPTED: 'Accord de principe',
    BED_ASSIGNED: 'Lit affecté',
  },
  unitsTargeted: (result: number) => `${result} ${result > 1 ? 'unités fléchées' : 'unité fléchée'}`,
  targetRejected: (result: number) => `${result} refus`,
  requestRejected: {
    label: 'La demande est refusée...',
    description: 'Toutes les unités fléchées ont refusé la demande.',
    action: 'Vous pouvez indiquer un nouveau fléchage en modifiant la demande.',
  },
  visitSummary: {
    movementRequest: 'Demande de mutation',
    plannedAccommodation: 'Hébergement à venir',
    inProgressAccommodation: 'Hébergement actif',
    completedAccommodation: 'Hébergement terminé',
    hospitalizationRequest: 'Demande d’hospitalisation',
  },
  unitSearchNoOptions: 'Aucun résultat',
  createdAt: 'Créée le',
  departmentsEmptyContent: {
    title: 'Aucune unité ne correspond aux filtres sélectionnés',
    subtitle: 'Essayez autre chose !',
  },
  territoryEmptyContent: {
    title: 'Aucun établissement ne correspond aux filtres sélectionnés',
    subtitle: 'Essayez autre chose !',
  },
  accommodationEmptyContent: {
    title: 'Aucun séjour ne correspond aux filtres sélectionnés',
    subtitle: 'Essayez autre chose !',
  },
  bedListEmptyContent: {
    title: 'Aucun lit ne correspond aux filtres sélectionnés',
    subtitle: 'Essayez autre chose !',
  },
  receivedAt: 'Reçue le',
  unableToDragFromColumn: {
    admission: "Vous ne pouvez pas annuler l'admission du patient",
    discharge: 'Vous ne pouvez pas annuler la sortie du patient',
  },
  and: 'et',
  movementRequests: 'Demandes de mouvement',
  selectAll: 'Tout sélectionner',
  listViewHeads: {
    bed: 'Lit',
    status: 'Statut',
    age: 'Âge',
    patient: 'Patient',
    from: 'Depuis le',
    duration: 'Durée',
    to: "Jusqu'au",
    specificities: 'Spécificités',
  },
  openBed: 'Ouvrir le lit',
  placePatient: 'Placer un patient',
  placementSuggestions: 'Propositions de lits',
  noBedAvailableInUnit: 'Aucune proposition de lit dans cette unité',
  noBedAvailableSubtitle:
    'Nous n’avons pas trouvé de placement pertinent aux dates spécifiées. Vous pouvez toujours placer manuellement le patient :',
  chooseMyBed: 'Recherche manuelle',
  externalTransports: 'Transport sanitaire',
  externalTransportStatus,
  upcomingExternalTransport: 'Prochain transport',
  externalFarePosition: {
    LYING_DOWN: 'Allongé',
    SITTING_UP: 'Assis',
  },
  session: {
    createError: 'La création de la session a échoué',
    notFound: 'La session utilisateur est introuvable',
  },
  rejectionReason: 'Motif de refus',
  performerRequestSent: 'Demande envoyée',
  performerRequestRejected: 'Demande refusée',
  performerRequestAccepted: 'Accord de principe',
  performerRequestTooltip: {
    accepted: `Accord de principe déclaré le`,
    sent: `Demande envoyée le`,
    rejected: `Demande refusée le`,
    by: 'par',
  },
  timelineGroupedItemsTitle: 'Détail du groupe de séjours',
  movementListViewHeads: {
    date: 'Horaire',
    status: 'Statut',
    bed: 'Lit',
    patient: 'Patient',
    age: 'Age',
    duration: 'Durée',
    entryReason: "Motif d'entrée",
    specificities: 'Spécificités',
    destination: 'Destination',
    action: 'Action',
    from: 'Provenance',
  },
  movementView: {
    kanban: 'Vue tableau',
    list: 'Vue liste',
    sheets: 'Vue fiches',
  },
  movementStatuses: {
    accommodation_admitted: 'Patient admis',
    accommodation_assigned: 'Lit affecté',
    accommodation_confirmed_discharge: 'Validée',
    accommodation_discharged: 'Patient sorti',
    accommodation_planned_discharge: 'Prévisionnelle',
    accommodation_to_assign: 'À placer',
    incoming_mutation_requested: 'En attente',
    outgoing_mutation_blocked: 'Bloquée',
    outgoing_mutation_requested: 'En attente',
  },
  movementsByPage: 'Mouvements par page',
  selectedUnits: 'unités sélectionnées',
  myBeds: 'Mes lits',
  myMovements: 'Mes mouvements',
  myMovementsTitle: {
    admission: 'Entrées',
    discharge: 'Sorties',
    movements: 'Sorties / Entrées',
  },
  otherUnit: 'Une autre unité',
  placePatientSuccess: {
    title: 'C’est fait !',
    message: 'Le patient a bien été placé.',
  },
  placePatientError: 'Erreur lors du placement du patient.',
  accommodationsByPage: 'Séjours par page',
  disabledViewTooltip: 'Cette vue n’est pas disponible lorsque plusieurs unités sont sélectionnées.',
  access: 'Accéder',
  healthcareEnvironmentalCleaning: 'Bionettoyage',
  healthCareEnvironmentalCleaningStatus,
  cleaningRequestData: 'Informations sur la mission',
  requestReference: 'Référence de la mission',
  cleaningStatus: 'Statut du bionettoyage',
  start: 'Début',
  end: 'Fin',
  bedPlacementSolutions: (results: number) =>
    results > 1 ? ` propositions de lit dans cette unité` : ` proposition de lit dans cette unité`,
  bedPlacementTooltip:
    'Silbo se base sur les dates du séjour, le genre du patient, son âge et ses spécificités pour vous proposer les lits les plus pertinents',
  bedGender: 'Genre du lit',
  bedGenders,
  startDate: 'Date de début',
  endDate: 'Date de fin',
  add: 'Ajouter',
  enableOrDisableArea: "Activer/désactiver l'emplacement",
  addAvailabilityPeriod: 'Ajouter une disponibilité',
  startDateMandadatory: 'La date de début est obligatoire',
  endDateMustBeAfterStartDate: 'La date de fin doit être postérieure à la date de début',
  moveBed: 'Déplacer le lit',
  deleteArea: "Supprimer l'emplacement",
  move: 'Déplacer',
  newExternalIds: 'Nouveaux identifiants externes',
  newParent: 'Nouveau parent',
  activationDate: 'Date d’activation',
  parentDidNotChange: 'Le parent n’a pas changé',
  bedMovedSuccess: 'Le lit a bien été déplacé',
  bedDeletedSuccess: 'Le lit a bien été supprimé',
  the: 'Le',
  pageSubtitles: {
    healthCenter: 'Projection du capacitaire en fin de journée',
    territory: 'Occupation du territoire en fin de journée',
    beds: 'État des lits en temps réel',
    movements: {
      admission: `Mouvements entrants `,
      discharge: `Mouvements sortants `,
    },
    sheets: `Mouvements entrants et sortants `,
  },
  defaultOrigin: 'Domicile / autre',
  defaultDestination: 'Domicile / autre',
  patientHl7: {
    title: 'Messages HL7',
    loadData: 'Charger les données',
    all: 'Tous',
    noMessage: 'Pas de message...',
    pan: 'N° de séjour',
    adtTable: {
      status: 'Statut',
      createdAt: 'Date de création',
      msgId: 'Identifiant du message',
      eventType: 'Type d’événement',
      historicalMovement: 'Historique',
      originalEventType: 'Type d’événement original',
      ipp: 'IPP',
      pan: 'PAN',
      patientClass: 'Classe du patient',
      startMovementTime: 'Date de début du mouvement',
      movementId: 'Identifiant du mouvement',
      responsibleUnit: 'Unité responsable',
      unit: 'Unité',
      room: 'Chambre',
      bed: 'Lit',
      admittedAt: 'Date d’admission',
      dischargedAt: 'Date de sortie',
      expectedAdmissionDate: 'Date d’admission prévue',
      expectedDischargeDate: 'Date de sortie prévue',
      hospitalizationReason: 'Motif d’hospitalisation',
    },
    siuTable: {
      createdAt: 'Date de création',
      msgId: 'Identifiant du message',
      eventType: 'Type d’événement',
      ipp: 'IPP',
      pan: 'PAN',
      patientClass: 'Classe du patient',
      service: 'Service',
      unit: 'Unité',
      room: 'Chambre',
      bed: 'Lit',
      personel: 'Personnel acceuillant',
      placerId: 'Placer ID',
      fillerId: 'Filler ID',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      duration: 'Durée',
      status: 'Statut',
      priority: 'Priorité',
      reason: 'Motif',
      comment: 'Commentaire',
    },
  },
  bookedBedConfig: {
    title: 'Configuration des lits réservés',
    delayRule: 'Délai avant un nouvel hébergement',
    delayType: {
      TODAY_AT: 'Aujourd’hui à',
      TOMORROW_AT: 'Demain à',
      HOURS: 'Dans',
    },
    hour: 'heure',
  },
  responsibleUnits: 'Unités responsables',
  responsibleUnit: 'Unité responsable',
  accommodatedPatients: 'Patients hébergés',
  accommodatedIn: 'Hébergés en',
  accommodatedAccommodations: 'Hébergés',
  accommodatedOtherUnitTooltip: 'Patients rattachés à mon unité hébergés ailleurs',
  createRequest: 'Créer la demande',
  createHospitalizationRequest: "Faire une demande d'hospitalisation",
  createHospitalizationRequestError: "Erreur lors de la demande d'hospitalisation",
  hospitalizationRequest: "Demande d'hospitalisation",
  hospitalizationRequests: 'Demandes d’hospitalisation',
  empty: 'Vide',
  actions: 'Actions',
  presentPatients: {
    title: 'Patients présents',
    noPatient: 'Aucun patient trouvé',
    requestHospitalization: "Demander l'hospitalisation",
    requestBeingProcessed: 'Demande en cours',
    patientDetails: 'Détails du patient',
    table: {
      patient: 'Patient',
      age: 'Âge',
      since: 'Depuis',
      specificities: 'Spécificités',
      comment: 'Commentaire',
      action: 'Action',
      patientsPerPage: 'Patients par page',
      bed: 'Lit',
    },
  },
  hospitalizationRequestsPage: {
    title: "Demandes d'hospitalisation en cours",
    noRequest: 'Aucune demande trouvée',
    table: {
      patient: 'Patient',
      age: 'Âge',
      status: 'Statut',
      bed: 'Lit',
      destination: 'Destination',
      hour: 'Heure',
      hospitalizationReason: "Motif d'hospitalisation",
      specificities: 'Spécificités',
      comment: 'Commentaire',
      action: 'Action',
      requestsPerPage: 'Demandes par page',
    },
  },
  searchPatient: 'Rechercher un patient',
  expandResults: 'Afficher tous les résultats',
  movementTypes,
  movementSheetsEmptyContent: {
    title: 'Aucun mouvement programmé actuellement',
    subtitle: 'Revenez plus tard',
  },
  placePatientEmpryContent: {
    title: 'Aucune entrée en attente',
    subtitle: "C'est calme par ici",
  },
  pan: 'Séjour',
  ipp: 'Ipp',
  ins: 'Ins',
  accommodationDetails: "Détails de l'hébergement",
  accommodationInfos: "Informations sur l'hébergement",
  cardEvents: {
    fares: 'Brancardage',
  },
  detailsDialog: {
    informations: 'Informations',
    visitTitle: 'Parcours du patient',
    locationTitle: 'Planning du lit',
    tabs: {
      current: 'En cours',
      past: 'Passé',
      future: 'À venir',
      movement: 'Mouvement',
    },
    accommodations: {
      titles: {
        past: 'Hébergement passé',
        current: 'Hébergement en cours',
        future: 'Hébergement à venir',
      },
      content: {
        start: 'Entrée',
        end: 'Sortie',
      },
    },
    fare: {
      title: 'Détails du brancardage',
      content: {
        ticket: {
          start: 'Entrée en',
          end: 'Sortie vers',
        },
      },
    },
    availableBed: {
      createAccommodation: 'Programmer un séjour',
      closeBed: 'Programmer la fermeture du lit',
    },
    common: {
      hospitalizationReason: "Motif d'hospitalisation",
      responsibleUnit: 'Unité responsable',
      unknownStep: 'Étape introuvable',
      practitioners: 'Médecins',
      noEndTime: '__ : __',
    },
    toasts: {
      saveSuccess: {
        title: "C'est noté !",
        content: 'Les modifications ont bien été enregistrées.',
      },
      saveError: {
        title: 'Oups !',
        content: "Erreur lors de l'enregistrement des modifications.",
      },
    },
  },
  leaveWithoutSaving: {
    title: 'Ne partez pas trop vite !',
    content:
      'Vous êtes sur le point de quitter la fenêtre sans sauvegarder.\nSouhaitez-vous sauvegarder ou annuler les dernières modifications effectuées ?',
  },
  toDefine: 'À définir',
  copy: 'Copier',
  copied: 'Copié !',
  anomalies: 'Anomalies',
  whitelistedIPs: 'Adresses IP autorisées',
  ipAdresses: 'Adresses IP',
  isReadOnly: 'Lecture seule',
  searchUnit: 'Rechercher une unité',
  visit: 'Séjour',
  visits: 'Séjours',
  microsoftLogin: 'Se connecter avec Microsoft',
  noMicrosoftAccountLinked:
    "Ce compte Microsoft n'est pas lié avec Silbo. Veuillez utiliser vos identifiants habituels.",
  autoComputeDischarge: 'Activer la sortie automatique des patients',
  practitionersAutocomplete: {
    placeholder: 'Nom prénom médecin',
    helper:
      'Le médecin n’est pas dans la liste ? Renseignez son nom puis cliquez sur “Ajouter” ou appuyez sur la touche entrée.',
  },
  practitioners: 'Médecins',
  noPractitionersFound: 'Aucun médecin trouvé',
  practitionerNotDefined: 'Pas de médecin renseigné',
  adults: 'Adultes',
  pediatric: 'Pédiatrique',
  mutationStatus: {
    pending: 'En attente',
    blocked: 'Bloquée',
    ended: 'Terminée',
    validated: 'Validée',
    toAddress: 'À flécher',
    accepted: 'Acceptée',
  },
  admissionStatus: {
    patientAdmitted: 'Patient admis',
    bedAssigned: 'Lit affecté',
    toPlace: 'À placer',
  },
  anomalyStatuses,
  anomalyTypes,
  outcome: 'Devenir',
  outcomes: 'Devenirs',
  precisions: 'Précisions',
  isEmergency: "Unité d'urgences",
  loading: 'Chargement...',
  viaTrajectoire: {
    reportTypes: {
      'grand-age': 'Grand-âge',
      sanitaire: 'Sanitaire',
    },
    createReport: 'Créer un dossier ViaTrajectoire',
  },
  ordered: 'Commandé',
  validated: 'Validé',
  orderExternalTransport: 'Commander un transport',
  thesaurus: 'Thésaurus',
  activePlural: 'Actifs',
  disabledPlural: 'Désactivés',
  accountHasBeenDisabled: 'Le compte a bien été désactivé',
  errorDisablingAccount: 'Erreur lors de la désactivation du compte',
  accountHasBeenReEnabled: 'Le compte a bien été réactivé',
  errorReEnablingAccount: 'Erreur lors de la réactivation du compte',
  disableAccount: 'Désactiver le compte',
  reEnableAccount: 'Réactiver le compte',
  reEnable: 'Réactiver',
  accountDisabledReason: 'Motif de désactivation',
  accountDisabledReasonQuestion: 'Quel est le motif de désactivation du compte ?',
  reEnableAccountQuestion: 'Êtes-vous sûr de vouloir réactiver ce compte ?',
  USER_DISABLED: "Ce compte a été désactivé, veuillez contacter l'administrateur",
  lastConnectionAt: 'Dernière connexion le',
  neverConnected: 'Jamais connecté',
  refresh: 'Rafraîchir',
  thesaurusReasonPlaceholder: "Taper ou sélectionner le motif d'hospitalisation",
  personalizedReason: 'Motif personnalisé',
  thesaurusReason: "Motifs d'hospitalisation dans le",
  thesaurusSuggestionsOf: 'Propositions du',
  bedsOverview: {
    title: 'Synthèse Gestion des lits',
    department: 'Pôle',
    unit: 'Unité',
    bed: 'Lit',
    status: 'Statut',
    patient: 'Patient',
    age: 'Âge',
    specificities: 'Spécificités',
    unitResponsible: 'Unité responsable',
    startDate: "Date d'entrée",
    endDate: 'Date de sortie',
    movement: 'Mouvement',
    destination: 'Fléchage',
    hour: 'Horaire',
    prevShort: 'Prév.',
    confirmedShort: 'Conf.',
    effectiveShort: 'Eff.',
    doctor: 'Médecin',
  },
};
